import { partnerHostLive, partnerHostStaging } from '../../constants'
import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
const Host = window.location.hostname
const AddCard = ({ type, token, partnerType }) => {
  return (
    <>
      {type === 'car' ? (
        <Link
          to={`${
            Host === partnerHostLive || Host === partnerHostStaging
              ? '/'
              : '/partner/'
          }driver/app/${token}/car/add`}
          className='driver-add-card mb-3'
        >
          <div className='add-pic-container'>
            <img
              className='car-img'
              src='/images/icons/icon-vehicle-grey.png'
              alt=''
            />
          </div>
          <span className='add-span'> Add vehicle </span>

          <img
            className='add-img-car'
            src='/images/icons/add-circle.png'
            alt=''
          />
        </Link>
      ) : (
        <Link
          to={`${
            Host === partnerHostLive || Host === partnerHostStaging
              ? '/'
              : '/partner/'
          }driver/app/${token}/chauffeur/add`}
          className='driver-add-card mb-3'
        >
          <div className='add-pic-container'>
            <img
              className='car-img-chauffeur'
              src='/images/icons/icon-chauffeur-grey.png'
              alt=''
            />
          </div>
          <span className='add-span'>
            Add{' '}
            {partnerType === 'rental'
              ? 'agent'
              : partnerType === 'meetGreet'
              ? 'greeter'
              : 'chauffeur'}
          </span>

          <img className='add-img' src='/images/icons/add-circle.png' alt='' />
        </Link>
      )}
    </>
  )
}

export default withRouter(AddCard)
