import { createOrEditItem, deleteItem } from './form'

export const addPromotionCode = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `addPromoCode`,
      successMessage: 'Promotion code was successfully created',
    })
  )

export const updatePromotionCode = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `promoCode/${id}`,
      successMessage: 'Promotion code was successfully updated',
    })
  )

export const deletePromoCode = id => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `promoCode/${id}`,
      successMessage: 'Promotion code was successfully Deleted',
    })
  )
export const activatePromoCode = (id, values) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      editRequest: 'put',
      endpoint: `promoCode/${id}`,
      successMessage: 'Promotion code was successfully updated',
    })
  )
