import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation, useParams, withRouter } from 'react-router-dom'
import { useGetTableData, useGetInitialFormValues } from '../../../../../hooks'
import { connectedApiService as api } from '../../../../../index'
import AddCard from '../../../../../components/DriversApp/AddCard'
import FormRadio from '../../../../../components/FormRadio'
import PartnerAcceptedModal from '../../../../../components/PartnerAcceptedModal'
import { assignGreeter, completeAssign, completeAssignGreeter } from 'actions'
import DefaultDriverDesktopLayout from 'components/layout/DefaultDriverDesktopLayout'
import { Button, Row, Col } from 'react-bootstrap'
import { isMobile, formatSentenceCase } from '../../../../../helpers'
import LoadingSpinner from 'components/LoadingSpinner'
import FormFieldWithIcon from 'components/FormFieldWithIcon'
import { partnerHostLive, partnerHostStaging } from '../../../../../constants'
import _ from 'lodash'

const Host = window.location.hostname
const isMobileBrowser = isMobile()

const Assign = ({ history, assignGreeter, completeAssign }) => {
  const { token, bookingId } = useParams()

  let location = useLocation()
  let currentLocation = location.pathname

  const [isLoading, setIsLoading] = useState(false)
  const [selectedGreeter, setSelectedGreeter] = useState(null)
  const [selectedTab, setSelectedTab] = useState(0)
  const [multipleRowsList, setMultipleRowsList] = useState([])
  const [driverExists, setDriverExists] = useState(true)
  const [showAssignedModal, setShowAssignedModal] = useState(false)
  const [sameGreeter, setSameGreeter] = useState(false)

  const { currentItem, getCurrentItem } = useGetInitialFormValues(
    `external/vipPartnerBooking/${bookingId}/${token}`
  )

  const {
    data: { list },
    reloadList,
    onFiltersChange,
    filters,
  } = useGetTableData(`external/greeter/${token}`)

  useEffect(() => {
    if (currentItem) {
      if (currentLocation.includes('assign')) {
        setSelectedGreeter(
          currentItem.partnerGreeterIdTemp
            ? currentItem.partnerGreeterIdTemp
            : currentItem.partnerGreeterId
            ? currentItem.partnerGreeterId
            : null
        )
        setSelectedTab(0)
      } else if (currentLocation.includes('confirm')) {
        setSelectedTab(1)
        if (!currentItem.partnerGreeterIdTemp) {
          setSameGreeter(true)
        } else {
          setSameGreeter(false)
        }
      }
    }
  }, [currentItem, currentLocation])

  useEffect(() => {
    const checkDriverExists = async phoneNumber => {
      const url = `external/findDriver/${phoneNumber}`
      const { data } = await api.get(url)

      setDriverExists(data.valid)
    }
    if (list) {
      let length = 2
      let rest = list.length % 2
      let size = Math.floor(list.length / 2)
      let index = 0
      setMultipleRowsList(
        Array.from({ length }, (_, i) =>
          list.slice(index, (index += size + (i < rest)))
        )
      )
      if (currentLocation.includes('confirm') && list[0] && list[0].phoneNumber)
        checkDriverExists(list[0].phoneNumber)
    }
    if (list && list[0] && list[0].firstName && list[0].lastName) {
      list[0].firstName = formatSentenceCase(list[0].firstName)
      list[0].lastName = formatSentenceCase(list[0].lastName)
    }
  }, [list, currentItem])

  const sendSMS = async () => {
    try {
      if (
        currentLocation.includes('confirm') &&
        list[0] &&
        list[0].phoneNumber
      ) {
        const url = `external/sendSmsToDriver/${list[0].phoneNumber}`
        await api.get(url)
        reloadList()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const redirectAssign = () => {
    try {
      if (currentLocation.includes('chauffeur')) {
        history.replace(
          `${
            Host === partnerHostLive || Host === partnerHostStaging
              ? '/'
              : '/partner/'
          }driver/app/details/${token}/${bookingId}`
        )
      } else if (currentLocation.includes('vehicle')) {
        history.replace(
          `${
            Host === partnerHostLive || Host === partnerHostStaging
              ? '/'
              : '/partner/'
          }driver/app/details/chauffeur/${token}/${bookingId}`
        )
      } else if (currentLocation.includes('pickup')) {
        history.replace(
          `${
            Host === partnerHostLive || Host === partnerHostStaging
              ? '/'
              : '/partner/'
          }driver/app/details/vehicle/${token}/${bookingId}`
        )
      } else if (currentLocation.includes('confirm')) {
        if (
          currentItem &&
          (currentItem.airportPickUpInfo || currentItem.trainPickUpInfo)
        ) {
          history.replace(
            `${
              Host === partnerHostLive || Host === partnerHostStaging
                ? '/'
                : '/partner/'
            }driver/app/details/pickup/${token}/${bookingId}`
          )
        } else {
          history.replace(
            `${
              Host === partnerHostLive || Host === partnerHostStaging
                ? '/'
                : '/partner/'
            }driver/app/details/vehicle/${token}/${bookingId}`
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const selectChauffeur = id => {
    if (selectedGreeter !== id) setSelectedGreeter(id)
  }

  const onSubmit = async () => {
    setIsLoading(true)
    if (currentLocation.includes('assign')) {
      let values = {
        chauffeurId: selectedGreeter,
      }
      let data = await assignGreeter(values, bookingId)
      if (data) {
        await getCurrentItem()
        setIsLoading(false)
        history.replace(
          `${
            Host === partnerHostLive || Host === partnerHostStaging
              ? '/'
              : '/partner/'
          }driver/app/details/greeter/confirm/${token}/${bookingId}`
        )
      }
    } else {
      const geo = `00.00-00.00`
      let values = {
        token: token,
        action: 'accepted',
        geoLocation: geo,
        isLiveLocation: false,
      }
      await completeAssignGreeter(values, bookingId)
      setShowAssignedModal(true)
      setTimeout(
        async function() {
          await getCurrentItem()
          history.replace(
            `${
              Host === partnerHostLive || Host === partnerHostStaging
                ? '/'
                : '/partner/'
            }driver/app/details/vip/${token}/${bookingId}`
          )
        }.bind(this),
        1500
      )
    }
  }

  const renderContent = () => {
    return (
      <>
        {isLoading && <LoadingSpinner />}
        {list && (
          <div className='driver-booking-container' style={{ margin: 0 }}>
            <div className='tabs-container' style={{ width: 'fit-content' }}>
              <div className='d-flex justify-content-start'>
                <div className='assign-stepper'>
                  <div className={`nav-link completed`}>Select greeter</div>
                  <div
                    className={`nav-link ${
                      selectedTab === 1 ? 'completed' : 'not-completed'
                    }`}
                  >
                    Confirm
                  </div>
                </div>
              </div>
            </div>

            {selectedTab === 0 && (
              <>
                <div
                  className={`driver-service-card ${
                    list.length <= 3
                      ? 'assign-select-card'
                      : 'assign-select-card-multiple'
                  }`}
                >
                  <div className='driver-service-card-header pb-4 pt-3'>
                    <span className='select-title'>My greeters</span>
                  </div>
                  {list.length ? (
                    <div className='partner-search-container'>
                      <div class='col pl-0'>
                        <FormFieldWithIcon
                          field={{
                            name: 'search-chauffeur',
                            placeHolder: 'Search greeter name',
                            value: filters['chauffeurName'],
                            onChange: onFiltersChange('chauffeurName'),
                          }}
                          icon='icons/Icon_Search_Inactive.png'
                          className='partner-search-bar'
                        />
                      </div>
                      <Link
                        to={`${
                          Host === partnerHostLive ||
                          Host === partnerHostStaging
                            ? '/'
                            : '/partner/'
                        }driver/app/${token}/chauffeur/add`}
                        className='partner-link-desktop'
                      >
                        <Button
                          className='partner-btn-desktop-secondary-assign select-vehicle'
                          variant='brown'
                        >
                          Add greeter
                        </Button>
                      </Link>
                    </div>
                  ) : (
                    <> </>
                  )}
                  {list.length ? (
                    <>
                      {list.length <= 3 ? (
                        list.map(item => (
                          <div
                            className={`chauffeur-card-item ${
                              item._id === selectedGreeter
                                ? 'selected-item'
                                : ''
                            }`}
                          >
                            {item.profilePicture ? (
                              <div className='add-pic-container-uploaded'>
                                <img
                                  className='profile-img'
                                  src={item.profilePicture}
                                />
                              </div>
                            ) : (
                              <div className='add-pic-container-uploaded'>
                                <img
                                  className='car-img-chauffeur'
                                  src={'/images/icons/icon-chauffeur-black.png'}
                                />
                              </div>
                            )}
                            <span
                              className='info-span-chauffeur'
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {item.firstName && item.lastName ? (
                                <span
                                  className='chauffeur-title d-block'
                                  style={{
                                    fontWeight: 'bolder',
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {formatSentenceCase(item.firstName)}{' '}
                                  {formatSentenceCase(item.lastName)}
                                </span>
                              ) : (
                                <></>
                              )}
                              <span style={{ color: '#8b959e' }}>
                                {item.phoneNumber}
                              </span>
                            </span>
                            <FormRadio
                              name={`driver${item._id}`}
                              id={`driver${item._id}`}
                              label=''
                              className='mb-0 mt-3'
                              checked={selectedGreeter === item._id}
                              handleChange={() => {
                                selectChauffeur(item._id)
                              }}
                            />
                          </div>
                        ))
                      ) : (
                        <div className='partner-grid'>
                          {multipleRowsList.map(items => (
                            <div>
                              {items.map(item => (
                                <div
                                  className={`chauffeur-card-item ${
                                    item._id === selectedGreeter
                                      ? 'selected-item'
                                      : ''
                                  }`}
                                >
                                  {item.profilePicture ? (
                                    <div className='add-pic-container-uploaded'>
                                      <img
                                        className='profile-img'
                                        src={item.profilePicture}
                                      />
                                    </div>
                                  ) : (
                                    <div className='add-pic-container-uploaded'>
                                      <img
                                        className='car-img-chauffeur'
                                        src={
                                          '/images/icons/icon-chauffeur-black.png'
                                        }
                                      />
                                    </div>
                                  )}
                                  <span
                                    className='info-span-chauffeur'
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    {item.firstName && item.lastName ? (
                                      <span
                                        className='chauffeur-title d-block'
                                        style={{
                                          fontWeight: 'bolder',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        {formatSentenceCase(item.firstName)}{' '}
                                        {formatSentenceCase(item.lastName)}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                    <span style={{ color: '#8b959e' }}>
                                      {item.phoneNumber}
                                    </span>
                                  </span>
                                  <FormRadio
                                    name={`driver${item._id}`}
                                    id={`driver${item._id}`}
                                    label=''
                                    className='mb-0 mt-3'
                                    checked={selectedGreeter === item._id}
                                    handleChange={() => {
                                      selectChauffeur(item._id)
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className='chauffeur-card-item extra-spacing'>
                      <div className='d-flex align-items-center'>
                        <div className='add-pic-container'>
                          <img
                            className='car-img-chauffeur'
                            src={'/images/icons/icon-chauffeur-grey.png'}
                          />
                        </div>
                        <span
                          className='info-span-small ml-3'
                          style={{ whtieSpace: 'pre' }}
                        >
                          <span style={{ color: '#8b959e' }}>
                            No active greeter
                          </span>
                        </span>
                      </div>
                      <Link
                        to={`${
                          Host === partnerHostLive ||
                          Host === partnerHostStaging
                            ? '/'
                            : '/partner/'
                        }driver/app/${token}/chauffeur/add`}
                      >
                        <Button
                          className='partner-btn-desktop-small select-vehicle'
                          variant='brown'
                          style={{
                            border: '1px solid #a79779',
                            backgroundColor: '#a79779',
                          }}
                        >
                          Add greeter
                        </Button>
                      </Link>
                    </div>
                  )}
                </div>
                {list.length ? (
                  <div
                    className={
                      list.length <= 3
                        ? 'partner-next-container-assign'
                        : 'partner-next-container-assign-multi'
                    }
                  >
                    <Button
                      className='partner-btn-desktop-primary select-vehicle'
                      variant='brown'
                      disabled={!selectedGreeter}
                      onClick={() => {
                        onSubmit()
                      }}
                      style={{
                        border: '1px solid #a79779',
                        backgroundColor: '#a79779',
                      }}
                    >
                      Next
                    </Button>
                  </div>
                ) : (
                  <> </>
                )}
              </>
            )}
            {selectedTab === 1 && list.length && (
              <Row className='assign-confirm-container'>
                <Col
                  md={6}
                  className='driver-service-card assign-select-card-confirm'
                >
                  <div className='driver-service-card-header pb-4 pt-3'>
                    <span className='select-title'>Assign the greeter</span>
                  </div>
                  <div className='chauffeur-card-item-confirm'>
                    {list[0].profilePicture ? (
                      <div style={{ width: '100vw', paddingLeft: '13px' }}>
                        <div className='add-pic-container-uploaded'>
                          <img
                            className='profile-img'
                            src={list[0].profilePicture}
                          />
                        </div>
                      </div>
                    ) : (
                      <div style={{ width: '100vw', paddingLeft: '13px' }}>
                        <div className='add-pic-container'>
                          <img
                            className='car-img-chauffeur'
                            src={'/images/icons/icon-chauffeur-grey.png'}
                          />
                        </div>
                      </div>
                    )}
                    <span className='info-span'>
                      <span
                        className='d-block'
                        style={{
                          fontWeight: 'bolder',
                          textTransform: 'capitalize',
                        }}
                      >
                        {list[0].firstName} {list[0].lastName}
                      </span>
                      <span style={{ color: '#8b959e' }}>
                        {list[0].phoneNumber}
                      </span>
                    </span>
                    <img
                      width={20}
                      src='/images/icons/icon-checklist-black.png'
                    />
                  </div>
                  {sameGreeter && (
                    <span className='mismatch-text mt-1'>
                      The greeter selected is already assigned to this booking.
                    </span>
                  )}
                  <div
                    className='partner-next-container'
                    style={sameGreeter ? { marginTop: '3em' } : {}}
                  >
                    <Button
                      className='partner-btn-desktop-tertiary select-vehicle'
                      onClick={() => {
                        history.push(
                          `${
                            Host === partnerHostLive ||
                            Host === partnerHostStaging
                              ? '/'
                              : '/partner/'
                          }driver/app/details/greeter/confirm/${token}/${bookingId}`
                        )
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      className='partner-btn-desktop-primary select-vehicle'
                      disabled={sameGreeter}
                      variant='brown'
                      onClick={() => {
                        onSubmit()
                      }}
                      style={{
                        border: '1px solid #a79779',
                        backgroundColor: '#a79779',
                      }}
                    >
                      Assign
                    </Button>
                  </div>
                </Col>

                {!driverExists && (
                  <Col
                    md={5}
                    className='driver-service-card'
                    style={{ marginTop: '2em', height: 'fit-content' }}
                  >
                    <div className='driver-service-card-header-app'>
                      <img src='/images/icons/icon-app.png' />
                      <span>ROLZO Partner App for greeters</span>
                    </div>
                    <div>
                      <p className='partner-app-para'>
                        {list[0].firstName} {list[0].lastName} is required to
                        download the ROLZO Partner App. Please note that no
                        price will be displayed to the greeter.
                      </p>
                    </div>
                    <Button
                      className='partner-btn-desktop-secondary select-vehicle'
                      variant='brown'
                      onClick={() => sendSMS()}
                    >
                      Send app download link by SMS
                    </Button>
                  </Col>
                )}
              </Row>
            )}
          </div>
        )}
      </>
    )
  }

  const renderModalContent = () => {
    return <PartnerAcceptedModal isGreeter={true} isBookingAccepted={false} />
  }

  return (
    <>
      {!isMobileBrowser ? (
        <>
          {currentItem ? (
            <DefaultDriverDesktopLayout
              data={currentItem}
              renderContent={
                !showAssignedModal ? renderContent : renderModalContent
              }
            />
          ) : (
            <> </>
          )}
        </>
      ) : (
        <>
          {!showAssignedModal ? (
            <div style={{ height: '100vh', overflow: 'hidden' }}>
              {isLoading && <LoadingSpinner />}
              <img
                className='driver-back-icon-assign'
                src='/images/icon-back.png'
                onClick={() => redirectAssign()}
                alt=''
              />
              <div className='driver-booking-container-header'>
                {currentItem && <span> Booking #{currentItem.number} </span>}
              </div>

              {currentLocation.includes('assign') ? (
                <>
                  <div className='driver-booking-assign-body'>
                    <h1>Select a greeter</h1>
                    <div className='mb-3'>
                      <FormFieldWithIcon
                        field={{
                          name: 'search-chauffeur',
                          placeHolder: 'Select greeter',
                          value: filters['chauffeurName'],
                          onChange: onFiltersChange('chauffeurName'),
                        }}
                        icon='icons/Icon_Search_Inactive.png'
                        roundFormField={true}
                      />
                    </div>
                    <AddCard
                      type={'chauffeur'}
                      token={token}
                      partnerType={'meetGreet'}
                    />
                    <div>
                      {list &&
                        list.map(item => (
                          <div
                            className={`driver-add-card mb-3 ${
                              selectedGreeter === item._id ? 'add-border' : ''
                            }`}
                          >
                            {item.profilePicture ? (
                              <div className='add-pic-container-uploaded'>
                                <img
                                  className='profile-img'
                                  src={item.profilePicture}
                                />
                              </div>
                            ) : (
                              <div className='add-pic-container-uploaded'>
                                <img
                                  className='car-img-chauffeur'
                                  src={'/images/icons/icon-chauffeur-black.png'}
                                />
                              </div>
                            )}
                            <span className='info-span'>
                              <span
                                className='d-block'
                                style={{
                                  fontWeight: 'bolder',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {item.firstName} {item.lastName}
                              </span>
                              <span> {item.phoneNumber} </span>
                            </span>
                            <FormRadio
                              name={`driver${item._id}`}
                              id={`driver${item._id}`}
                              label=''
                              className='mb-0 mt-3'
                              labelClass='custom-radio-partner'
                              checked={selectedGreeter === item._id}
                              handleChange={() => {
                                selectChauffeur(item._id)
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className='driver-booking-cta-footer-assign'>
                    <Button
                      className='select-btn select-vehicle'
                      style={{
                        width: '100%',
                        marginBottom: '15px',
                        fontWeight: '900',
                        fontSize: '16px',
                        textShadow: '0.25px 0 #ffffff',
                      }}
                      variant='brown'
                      onClick={() => {
                        onSubmit()
                      }}
                      disabled={!selectedGreeter}
                    >
                      Next
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  {list && list[0] && list[1] && (
                    <>
                      <div className='driver-booking-assign-body'>
                        <h1>Assign the greeter</h1>
                        <div className='driver-add-card mb-3'>
                          {list[0].profilePicture ? (
                            <div
                              className='add-pic-container-uploaded'
                              style={{ marginLeft: '1em' }}
                            >
                              <img
                                className='profile-img'
                                src={list[0].profilePicture}
                              />
                            </div>
                          ) : (
                            <div
                              className='add-pic-container-uploaded'
                              style={{ marginLeft: '1em' }}
                            >
                              <img
                                className='car-img-chauffeur'
                                src={'/images/icons/icon-chauffeur-black.png'}
                              />
                            </div>
                          )}
                          <span
                            className='info-span'
                            style={{ marginLeft: '2em' }}
                          >
                            <span
                              className='d-block'
                              style={{
                                fontWeight: 'bolder',
                                textTransform: 'capitalize',
                              }}
                            >
                              {list[0].firstName} {list[0].lastName}
                            </span>
                            <span> {list[0].phoneNumber} </span>
                          </span>
                          <img
                            width={20}
                            src='/images/icons/icon-checklist-black.png'
                            alt=''
                          />
                        </div>
                        {sameGreeter && (
                          <span className='mismatch-text mt-1'>
                            The greeter selected is already assigned to this
                            booking.
                          </span>
                        )}
                      </div>
                      <div className='driver-booking-cta-footer-assign'>
                        <Button
                          disabled={sameGreeter}
                          className='select-btn select-vehicle'
                          style={{
                            width: '100%',
                            marginBottom: '15px',
                            fontWeight: '900',
                            fontSize: '16px',
                            textShadow: '0.25px 0 #ffffff',
                          }}
                          variant='brown'
                          onClick={() => {
                            onSubmit()
                          }}
                        >
                          Assign
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          ) : (
            <PartnerAcceptedModal isGreeter={true} isBookingAccepted={false} />
          )}
        </>
      )}
    </>
  )
}

export default withRouter(
  connect(null, {
    assignGreeter,
    completeAssign,
  })(Assign)
)
