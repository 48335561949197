import _ from 'lodash'

const isCompanyAdmin = (userRoles = null) => {
  return (
    !!userRoles &&
    userRoles.company &&
    userRoles.company.includes('company_admin')
  )
}

export default class User {
  constructor({
    firstName = '',
    lastName = '',
    emails,
    email,
    authToken = null,
    userId = null,
    roles = {},
    companyId = null,
    isLoggedIn,
    phone,
    isMasqueradeUser = false,
    adminUserId = '',
    partnerToken = '',
    whatsNew = false,
    whatsNewPartner = false,
    showComIndicator = false,
    operationInsightId = '',
    timeZone = null,
    showComPopup = false,
  } = {}) {
    this.firstName = firstName
    this.lastName = lastName
    this.authToken = authToken
    this.adminUserId = adminUserId
    this.isMasqueradeUser = isMasqueradeUser
    this.userId = userId
    this.roles = roles
    this.emails = emails
    this.email = email
    this.phone = phone
    this.isLoggedIn = !!isLoggedIn
    this.companyId = companyId
    this.isCompanyAdmin = isCompanyAdmin(roles)
    this.partnerToken = partnerToken
    this.whatsNew = whatsNew
    this.whatsNewPartner = whatsNewPartner
    this.showComIndicator = showComIndicator
    this.showComPopup = showComPopup
    this.operationInsightId = operationInsightId
    this.timeZone = timeZone
  }
}

export const myProfileFormData = values => {
  return values
}
export const notificationSettingsData = values => {
  var data = {
    bookingUpdateByPushNotification: values.bookingUpdateByPushNotification,
    bookingUpdatesByEmail: values.bookingUpdatesByEmail,
    driverUpdateByEmail: values.driverUpdateByEmail,
    driverUpdateByPushNotification: values.driverUpdateByPushNotification,
    chauffeurDetails: values.chauffeurDetails,
    realTimeDetailsToPassenger: values.realTimeDetailsToPassenger,
    updateAllUsers: values.updateAllUsers,
    supportMessagesByEmail: values.supportMessagesByEmail,
    supportMessagesByPushNotification: values.supportMessagesByPushNotification,
    tenMinsAwayDriverUpdate: values.tenMinsAwayDriverUpdate,
  }
  return data
}

export const currencySettingsData = value => {
  var data = {}
  if (value.currencyType) {
    data = {
      currencyMethod: value.currencyMethod,
      currencyType: value.currencyType,
    }
  } else {
    data = {
      currencyMethod: value.currencyMethod,
    }
  }
  return data
}
export const pricingData = value => {
  return value
}
export const billingSettingsData = value => {
  return value
}
const currencyName = str => {
  return str.toLowerCase()
}
export class currencySettingsInitialValues {
  constructor({ currencySettings } = {}, currencies) {
    var list = []
    _.each(currencies, item => {
      list.push({
        label: item.description,
        value: currencyName(item.code), //currency_afn
      })
    })
    this.multipleCurrency =
      currencySettings.currencyMethod === 'multipleCurrency' ? true : false
    this.singleCurrency =
      currencySettings.currencyMethod === 'singleCurrency' ? true : false
    if (currencySettings.currencyType) {
      this.currencyChoice = list.find(item => {
        if (item.value === currencySettings.currencyType.split('_')[1]) {
          return {
            label: item.label,
            value: `${item.label} ${
              currencySettings.currencyType.split('_')[1]
            }`,
          }
        }
      })
    }
  }
}
export class pricingInitialValues {
  constructor({ pricingModel } = {}) {
    this.commissionable = pricingModel.commissionable
    this.netDiscount = pricingModel.netDiscount
  }
}
export class settingsInitialValues {
  constructor(
    {
      currencySettings = {},
      pricingModel = {},
      billingModel = {},
      defaultBenefits = {},
    } = {},
    currencies
  ) {
    var list = []
    _.each(currencies, item => {
      list.push({
        label: item.description,
        value: currencyName(item.code), //currency_afn
      })
    })
    this.multipleCurrency =
      currencySettings.currencyMethod === 'multipleCurrency' ? true : false
    this.singleCurrency =
      currencySettings.currencyMethod === 'singleCurrency' ? true : false

    //Currency choice with simple condition
    if (currencySettings.currencyType) {
      this.currencyChoice = list.find(item => {
        if (item.value === currencySettings.currencyType.split('_')[1]) {
          return {
            label: item.label,
            value: `${item.label} ${
              currencySettings.currencyType.split('_')[1]
            }`,
          }
        }
      })
    }
    this.commissionable =
      Object.keys(pricingModel).length === 0
        ? defaultBenefits?.commissionWeight
          ? true
          : false
        : pricingModel.commissionable || false

    this.netDiscount =
      Object.keys(pricingModel).length === 0
        ? defaultBenefits?.discountWeight
          ? true
          : false
        : pricingModel.netDiscount || false

    // this.commissionable = pricingModel.commissionable
    // this.netDiscount = pricingModel.netDiscount
    this.billToCompany = billingModel.billToCompany
    this.billToPassenger = billingModel.billToPassenger
  }
}
export class MyProfileInitialValues {
  constructor({ firstName = '', lastName = '', email, phone } = {}) {
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.phone = phone
  }
}
export class notificationSettingsInitialValues {
  constructor({ notificationSettings, settings } = {}) {
    this.bookingUpdateByPushNotification =
      notificationSettings.bookingUpdateByPushNotification
    this.bookingUpdatesByEmail = notificationSettings.bookingUpdatesByEmail
    this.driverUpdateByEmail = notificationSettings.driverUpdateByEmail
    this.driverUpdateByPushNotification =
      notificationSettings.driverUpdateByPushNotification
    this.chauffeurDetails = notificationSettings.chauffeurDetails ? true : false
    this.realTimeDetailsToPassenger = notificationSettings.realTimeDetailsToPassenger
      ? true
      : false
    this.supportMessagesByEmail = notificationSettings.supportMessagesByEmail
    this.supportMessagesByPushNotification =
      notificationSettings.supportMessagesByPushNotification
    this.tenMinsAwayDriverUpdate = notificationSettings.tenMinsAwayDriverUpdate
  }
}

export const changePasswordFormData = values => {
  return values
}
