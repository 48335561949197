import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router'
import Supportbar from '../../../components/layout/Supportbar'
import WhatsNewModal from 'components/WhatsNewModal'
import {
  hideComPopup,
  markPopUpAsSeen,
  setMessageOpen,
  setMessageClose,
  updateComInfo,
} from 'actions'
import { connect } from 'react-redux'
import { ComUpdateModal } from 'components/ComUpdateModal'
import SuccessMsg from 'components/SuccessMsg'
import { isMobile } from 'helpers'
import WhatsNewModalMobile from 'components/WhatsNewModalMobile'

const AppPage = ({
  component: View = null,
  parentPath,
  hasAccess,
  renderSidebar,
  renderBottomMenu,
  isTabRoute,
  hideSidebar = false,
  showBottomMenu = false,
  companyId = null,
  path,
  whatsNew,
  markPopUpAsSeen,
  showComPopup,
  showComIndicator,
  setMessageOpen,
  setMessageClose,
  updateComInfo,
  hideComPopup,
  ...props
}) => {
  const [showWhatsNew, setShowWhatsNew] = useState(false)
  const [isComPopupVisible, setIsComPopupVisible] = useState(showComPopup)
  const [viewedPopupIds, setViewedPopupIds] = useState([])
  const [hideWhatsNew, setHideWhatsNew] = useState(false)

  const isMobileBrowser = isMobile()

  const onHideCom = async () => {
    setIsComPopupVisible(false)
    await hideComPopup()
  }

  const onUpdateCom = async (id, values) => {
    await updateComInfo(id, values)
    setMessageOpen({
      title: `Thank you`,
      message: `Your company information has been submitted successfully.`,
    })

    setTimeout(() => {
      setMessageClose()
    }, 3000)
  }

  const renderComPopup = () => {
    return (
      <ComUpdateModal
        onHide={onHideCom}
        show={isComPopupVisible}
        companyId={companyId}
        onHandleFunction={onUpdateCom}
      />
    )
  }

  const onHide = async () => {
    setShowWhatsNew(false)
    setHideWhatsNew(true)

    if (viewedPopupIds.length > 0) {
      await markPopUpAsSeen({ popUps: viewedPopupIds })
    }

    if (isComPopupVisible) {
      setIsComPopupVisible(false)
      await hideComPopup()
    }
  }

  const handlePopupView = popupId => {
    if (!viewedPopupIds.includes(popupId)) {
      setViewedPopupIds(prevIds => [...prevIds, popupId])
    }
  }

  const renderPopUp = () =>
    !isMobileBrowser ? (
      <WhatsNewModal
        show={showWhatsNew}
        onHide={onHide}
        values={whatsNew}
        onViewPopup={handlePopupView}
      ></WhatsNewModal>
    ) : (
      <WhatsNewModalMobile
        show={showWhatsNew}
        onHide={onHide}
        values={whatsNew}
        onViewPopup={handlePopupView}
      />
    )

  const isIframeView = window.parent !== window.self
  const history = useHistory()

  useEffect(() => {
    if (
      window.innerWidth < 991 &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
    }
    if (document.getElementById('bodyClick')) {
      let node = document.getElementById('bodyClick')
      node.parentNode.removeChild(node)
    }
  }, [history.location.pathname])

  useEffect(() => {
    if (whatsNew && path.search(/\bpartner\b/) === -1 && !hideWhatsNew) {
      setShowWhatsNew(true)
    }
    if (showComPopup) {
      setIsComPopupVisible(true)
    }
  }, [whatsNew, showComPopup])

  useEffect(() => {}, [isComPopupVisible])

  if (!hasAccess) {
    return (
      <div className='p-5 text-center h5'>
        You don't have the correct permissions to view this page
      </div>
    )
  }
  if (!View) {
    return null
  }
  if (!!isTabRoute) {
    return (
      <div className={`${showBottomMenu ? 'page-with-bottom-menu' : ''}`}>
        {/* {props.isAccountView &&
        props.title === 'Settings' &&
        showComIndicator &&
        !isMobileBrowser ? (
          <div className='warning-container'>
            <div className='warning-text'>
              <img
                className='pdf-icon'
                src='/images/icons/payment/warning_message_icon.png'
                alt=''
              />
              <span>
                Your entity is missing important company details. ROLZO requires
                the information to verify your business and pay your
                commissions.
              </span>
            </div>
            <Button
              style={{
                whiteSpace: 'nowrap',
                marginRight: '1em',
                marginTop: '0.5em',
              }}
              className='inline-edit-btn btn-anchor btn-anchor'
              variant='link'
              onClick={() => {
                setIsComPopupVisible(true)
              }}
            >
              Update company profile
            </Button>
          </div>
        ) : (
          ''
        )} */}
        <View {...props} parentPath={parentPath} />
        {!!renderBottomMenu && !!showBottomMenu && renderBottomMenu()}
        {/* This is for the account page */}
        {props.isAccountView &&
        props.title === 'Settings' &&
        isComPopupVisible &&
        !isMobileBrowser ? (
          <>
            {renderComPopup()}
            <SuccessMsg isPayment />
          </>
        ) : (
          <></>
        )}
      </div>
    )
  }

  return (
    <>
      {!path.search(/\bpartner\b/) >= 0 ? (
        <div className='app-content'>
          {!!renderSidebar && !hideSidebar && !isIframeView && renderSidebar()}
          <div
            style={isIframeView ? { width: '100%' } : {}}
            className={`page-wrap ${hideSidebar ? 'w-100' : ''} ${
              companyId ? 'page-wrap-light' : 'page-wrap-dark'
            } ${showBottomMenu ? 'page-with-bottom-menu' : ''}`}
          >
            <div id='app-page'>
              <View
                {...props}
                hideSidebar={hideSidebar}
                showBottomMenu={showBottomMenu}
                parentPath={parentPath}
              />
            </div>
          </div>
          {!!renderBottomMenu && !!showBottomMenu && renderBottomMenu()}
          {/* There was a wierd 0 appears from the below condition , so added !! for the rest  */}
          {!!showWhatsNew &&
            !!whatsNew.length &&
            !!whatsNew[0].title &&
            !!whatsNew[0].description &&
            renderPopUp()}

          {/* This is for the main page */}
          {isComPopupVisible && !showWhatsNew && !isMobileBrowser ? (
            <>
              {renderComPopup()}
              <SuccessMsg isPayment />
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <View
          {...props}
          hideSidebar={hideSidebar}
          showBottomMenu={showBottomMenu}
          parentPath={parentPath}
        />
      )}
    </>
  )
}

const mapStateToProps = ({ auth }) => {
  return {
    whatsNew: auth.whatsNew,
    showComIndicator: auth.showComIndicator,
    showComPopup: auth.showComPopup,
    companyId: auth.companyId,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    markPopUpAsSeen,
    setMessageOpen,
    setMessageClose,
    updateComInfo,
    hideComPopup,
  })(AppPage)
)
