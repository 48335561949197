import React, { useMemo, useState } from 'react'
import { Redirect, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ProtectedRoute } from '../../shared/ProtectedRoute'
import AppPage from './AppPage'
import Sidebar from '../../../components/layout/Sidebar'
import BottomMenu from '../../../components/layout/BottomMenu'
import routes from './groups'
import { partnerHostLive, partnerHostStaging } from '../../../constants'
const Host = window.location.hostname
const isPartnerHost =
  window.location.hostname === partnerHostLive ||
  window.location.hostname === partnerHostStaging
    ? true
    : false
const getAllowedRoutes = (allRoutes, userRoles = {}) => {
  let allowedRoutes = []
  allRoutes.forEach(route => {
    const { allowedRoles, childRoutes, tabs, homeRoute } = route
    let hasAccess = !allowedRoles

    if (allowedRoles && userRoles) {
      for (let g in allowedRoles) {
        if (!userRoles[g]) {
          hasAccess = false
        } else {
          let group = allowedRoles[g] || []
          hasAccess = group.some(role => {
            return userRoles[g].includes(role)
          })
        }
      }
    }

    if (childRoutes && childRoutes.length) {
      if (hasAccess) {
        const {
          allowedRoutes: childAllowedRoutes,
          allRoutes: childAllRoutes,
        } = getAllowedRoutes(childRoutes, userRoles)
        route.childRoutes = childAllRoutes
        route.childAllowedRoutes = childAllowedRoutes
      } else {
        route.childRoutes.forEach(child => {
          child.hasAccess = false
        })
      }
    }

    if (tabs && tabs.length) {
      if (hasAccess) {
        const {
          allowedRoutes: tabsAllowedRoutes,
          allRoutes: tabsAllRoutes,
        } = getAllowedRoutes(tabs, userRoles)
        route.tabs = tabsAllRoutes
        route.tabsAllowedRoutes = tabsAllowedRoutes
      } else {
        route.tabs.forEach(child => {
          child.hasAccess = false
        })
      }
    }

    if (
      hasAccess &&
      (!childRoutes || route.childRoutes.length) &&
      (!tabs || route.tabs.length)
    ) {
      route.hasAccess = hasAccess
      allowedRoutes.push(route)
    } else {
      route.hasAccess = false
    }
  })

  return { allRoutes, allowedRoutes }
}
const AppRoutes = ({
  isLoggedIn,
  roles,
  partnerToken,
  companyId = null,
  email,
}) => {
  const { allowedRoutes, allRoutes } = useMemo(
    () => getAllowedRoutes(routes, roles),
    [roles]
  )

  const hasValidRoutes = !!allowedRoutes && !!allowedRoutes[0]
  const isPartnerRoute =
    hasValidRoutes && allowedRoutes[0].path.search(/\bpartner\b/) >= 0

  const redirectPath = useMemo(() => {
    if (isPartnerHost) {
      return hasValidRoutes
        ? isPartnerRoute
          ? `/driver/app/${partnerToken}/home-page`
          : allowedRoutes[0].path
        : !isLoggedIn
        ? '/auth'
        : null
    } else {
      if (email === 'marsel@rolzo.com') {
        return !!allowedRoutes && !!allowedRoutes[0] ? '/partners' : '/auth'
      }

      return !!allowedRoutes && !!allowedRoutes[0]
        ? allowedRoutes[0].path.search(/\bpartner\b/) >= 0
          ? `/partner/driver/app/${partnerToken}/home-page`
          : allowedRoutes[0].path
        : !isLoggedIn
        ? window.location.href.search(/\bpartner\b/) >= 0
          ? { pathName: '/partner/auth' }
          : '/auth'
        : null
    }
  }, [allowedRoutes, isLoggedIn, partnerToken])

  const renderSidebar = useMemo(
    () => () => <Sidebar routes={allowedRoutes} />,
    [allowedRoutes]
  )

  const renderBottomMenu = useMemo(
    () => () => <BottomMenu routes={allowedRoutes} />,
    [allowedRoutes]
  )

  return (
    <Switch>
      {allRoutes.map((route, i) => {
        const {
          path,
          isPrivate,
          childRoutes,
          exact,
          title,
          hasAccess,
          tabs,
        } = route
        return (
          <ProtectedRoute
            key={String(i)}
            isLoggedIn={isLoggedIn}
            isPrivate={isPrivate}
            path={path}
            childRoutes={childRoutes}
            exact={exact}
            title={title}
            hasAccess={hasAccess}
            tabs={tabs}
            renderSidebar={renderSidebar}
            renderBottomMenu={renderBottomMenu}
            companyId={companyId}
            component={() => (
              <AppPage
                renderSidebar={renderSidebar}
                renderBottomMenu={renderBottomMenu}
                isLoggedIn={isLoggedIn}
                tabs={tabs}
                companyId={companyId}
                {...route}
              />
            )}
          />
        )
      })}
      {!!redirectPath && <Redirect to={redirectPath} />}
    </Switch>
  )
}

const mapStateToProps = ({ auth }) => {
  const { isLoggedIn, roles, companyId, partnerToken, email } = auth

  return { isLoggedIn, roles, companyId, partnerToken, email }
}

export default withRouter(connect(mapStateToProps)(AppRoutes))
