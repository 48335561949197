import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getLabelFromValue,
  getFormattedPrice,
  numberWithCommas,
} from '../helpers'
import {
  defaultBookingServicePartner,
  defaultCurrencies,
  partnerHostLive,
  partnerHostStaging,
} from '../constants'
import { useLocation } from 'react-router-dom'
import { Col, Row, Button, Badge } from 'react-bootstrap'
import { acceptPartnerBooking } from '../actions/partners'
import _ from 'lodash'
import useBottomScrollListener from 'hooks/useBottomScrollListener'
const Host = window.location.hostname
const apiHost = process.env.REACT_APP_APIHOST
const dateFormatDetailed = 'ddd, Do MMM YYYY'

const DriverBookingCardVIP = ({
  data,
  reloadList,
  acceptPartnerBooking,
  setIsLoading,
  history,
  token,
  isNewBookingTab,
  isCompletedTab,
  isPlannedTab,
  isBookingsLoading = false,
  isPartner,
}) => {
  let location = useLocation()
  let currentLocation = location.pathname
  const [list, setList] = useState([])

  const isNewBookings =
    currentLocation && currentLocation.endsWith('/new-bookings')
  const isPlanned = currentLocation && currentLocation.endsWith('/planned')
  const isCompleted = currentLocation && currentLocation.endsWith('/completed')

  const title = isNewBookingTab
    ? !isPartner
      ? 'No new bookings'
      : 'No new offers'
    : isCompletedTab
    ? 'No completed bookings'
    : isPlannedTab && 'No planned bookings'
  const description = isNewBookingTab
    ? !isPartner
      ? 'We will notify you when we have a new booking'
      : 'We will notify you when we have a new offer'
    : isCompletedTab
    ? `You don't have any completed bookings yet`
    : isPlannedTab && `You don't have any planned bookings yet`

  const onAcceptBooking = async bookingId => {
    try {
      if (bookingId) {
        setIsLoading(true)
        // const geo = `51.4933,-0.1684`
        const geo = `00.00-00.00`
        let obj = {
          action: 'accepted',
          geoLocation: geo,
          isLiveLocation: false,
        }
        const res = await acceptPartnerBooking(obj, bookingId)
        if (res) setIsLoading(false)
        reloadList()
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    let items = data
    if (items && items.length) {
      for (var i in items) {
        const listItem = items[i]
        if (listItem && listItem.days && listItem.days.length) {
          const arr =
            listItem && listItem.dayCollapsibleArray
              ? listItem.dayCollapsibleArray
              : []
          if (arr && arr.length === 0) {
            for (var j in listItem.days) {
              arr.push(parseInt(j) === 0)
            }
          }
          items[i].dayCollapsibleArray = arr
        }
      }
    }
    setList(items)
  }, [data])

  const onViewOffer = async (bookingId, item) => {
    try {
      if (bookingId) {
        history.push(
          `${
            Host === partnerHostLive || Host === partnerHostStaging
              ? '/'
              : '/partner/'
          }driver/app/details/vip/${token}/${bookingId}`
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAddressElementLength = (containerIndex, divIndex, eleIndex) => {
    let container = document.getElementsByClassName(
      'partner-location-container'
    )[containerIndex]

    if (container) {
      let div = container.getElementsByClassName('partner-fields')[divIndex]

      if (div) {
        var selectedElement = div.getElementsByTagName('p')[eleIndex]

        if (selectedElement && selectedElement.offsetHeight) {
          if (selectedElement && selectedElement.offsetHeight > 70) return 3
          if (selectedElement && selectedElement.offsetHeight < 25) return 1
        }
        return 2
      }
      return 2
    }
    return 2
  }

  const renderDottedBar = addressLength => {
    return (
      <div
        className='dotted-container'
        style={
          addressLength === 3
            ? { height: '79px' }
            : addressLength === 1
            ? { height: '32px' }
            : { height: '56px' }
        }
      >
        <svg
          style={{
            transform: 'rotate(90deg)',
          }}
          width='300px'
          height='200px'
          viewBox='0 0 300 200'
          className='dotted-line'
        >
          <line
            x1='40'
            x2='260'
            y1='100'
            y2='100'
            stroke='#8b959e'
            stroke-width='2'
            stroke-linecap='round'
            stroke-dasharray='.001, 10'
          />
        </svg>
      </div>
    )
  }

  // #1e2b8e
  return (
    <>
      {data && data.length ? (
        <div className='driver-booking-container'>
          {data.map((item, index) => (
            <>
              {item.isForDriver ? (
                <></>
              ) : (
                <div
                  className='driver-booking-card'
                  style={
                    index === data.length - 1 ? { paddingBottom: '1rem' } : {}
                  }
                >
                  <div
                    class='card-top-indicator'
                    style={
                      item.status === 'cancellation_accepted_by_agent' ||
                      item.status === 'cancelled_by_agent'
                        ? { backgroundColor: '#d0021b' }
                        : isCompleted
                        ? { backgroundColor: 'black' }
                        : isNewBookings
                        ? list.isOperativeBranch &&
                          list.vipDispatchStatus === 'outsourced'
                          ? { backgroundColor: '#1e2b8e' }
                          : { backgroundColor: '#f5a623' }
                        : isPlanned
                        ? item.vipDispatchStatus === 'change_request'
                          ? { backgroundColor: '#ff6666' }
                          : { backgroundColor: '#417505' }
                        : {}
                    }
                  ></div>
                  <div className={`driver-booking-header driver-border-bottom`}>
                    <div className='driver-title-partner'>
                      <span>#{item.number}</span>
                      <span style={{ fontWeight: '900' }}>
                        VIP Meet & Greet
                      </span>
                      {isCompleted ? (
                        <Badge
                          href='#'
                          variant={
                            item.status === 'cancellation_accepted_by_agent' ||
                            item.status === 'cancelled_by_agent'
                              ? `cancelled-dispatch badge-outlined`
                              : 'completed-dispatch badge-outlined'
                          }
                        >
                          {item.status === 'cancellation_accepted_by_agent' ||
                          item.status === 'cancelled_by_agent'
                            ? 'CANCELLED'
                            : 'COMPLETED'}
                        </Badge>
                      ) : isPlanned ? (
                        <Badge
                          href='#'
                          variant={
                            item.status === 'cancellation_accepted_by_agent' ||
                            item.status === 'cancelled_by_agent'
                              ? `cancelled-dispatch badge-outlined`
                              : item.vipDispatchStatus === 'change_request'
                              ? 'change-request-dispatch badge-outlined'
                              : 'accepted-dispatch badge-outlined'
                          }
                        >
                          {item.status === 'cancellation_accepted_by_agent' ||
                          item.status === 'cancelled_by_agent'
                            ? 'CANCELLED'
                            : item.vipDispatchStatus === 'change_request'
                            ? 'CHANGE REQUEST'
                            : 'ACCEPTED'}
                        </Badge>
                      ) : (
                        <Badge href='#' variant={`new-booking badge-outlined`}>
                          {'PENDING'}
                        </Badge>
                      )}
                    </div>
                  </div>
                  <div
                    className='driver-booking-body'
                    style={{
                      border: '1.5px solid rgba(139, 149, 158, 0.2)',
                      padding: '1em 1em 2em 1em',
                      borderBottom: 'none',
                      borderTop: 'none',
                    }}
                  >
                    <div className='driver-content'>
                      <div className='passenger-name'>
                        {item.passengerInfo && item.passengerInfo.title}{' '}
                        {item.passengerInfo && item.passengerInfo.lastName}
                      </div>
                      <div className='passenger-details'>
                        <img src='/images/icons/team.svg' />
                        <span>
                          {item.passengerInfo &&
                            (item.passengerInfo.passenger === 0
                              ? 0
                              : item.passengerInfo.passenger)}
                        </span>
                        <img
                          src='/images/icons/portfolio.svg'
                          style={{
                            marginLeft: '10px',
                            width: '6.5% important',
                          }}
                          alt=''
                        />
                        <span>
                          {item.passengerInfo &&
                            (item.passengerInfo.luggage === 0
                              ? 0
                              : item.passengerInfo.luggage)}
                        </span>
                      </div>
                      <div className='date'>
                        <img src='/images/icon_calendar.png' />
                        <span>
                          {`
               ${moment.utc(item.pickUpDate).format(dateFormatDetailed)},
              ${moment.utc(item.pickUpDate).format('HH:mm')}
              `}
                        </span>
                      </div>
                      <div className='airport'>
                        <img src='/images/icons/Airport.png' />
                        <span>{item.airport}</span>
                      </div>
                      <div className='flight'>
                        <img src='/images/icons/Icon_Airport_Partner.png' />
                        <span>
                          {item.airportPickUpInfo
                            ? item.airportPickUpInfo.flightNumber.toUpperCase()
                            : item.airportDropOffInfo.flightNumber.toUpperCase()}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='driver-booking-price-card'>
                    {numberWithCommas(getFormattedPrice(item.dispatchPrice))}{' '}
                    {getLabelFromValue(
                      defaultCurrencies,
                      item.dispatchCurrency
                    )}
                  </div>
                  <div
                    className='driver-booking-cta'
                    style={
                      isCompleted
                        ? {
                            display: 'flex',
                            justifyContent: 'center',
                          }
                        : {}
                    }
                  >
                    <Button
                      className='select-btn select-vehicle partner-btn-mobile-primary'
                      style={{ width: '100%' }}
                      variant='brown'
                      onClick={() => {
                        onViewOffer(item._id, item)
                      }}
                    >
                      View
                    </Button>
                  </div>
                </div>
              )}
            </>
          ))}{' '}
        </div>
      ) : (
        <>
          {!isBookingsLoading && (
            <div className={`text-center no-data-wrap no-data-partner`}>
              <div className={`no-data-img-wrap`}>
                <img
                  src={'/images/icons/icon-bookings-grey.png'}
                  alt=''
                  width={25 || '50%'}
                />
              </div>
              <h4 className='no-data-header'>{title}</h4>
              <p className='no-data-description'>{description}</p>
            </div>
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = ({ auth: { isPartner = null, isDriver = null } }) => ({
  isPartner,
  isDriver,
})

export default withRouter(
  connect(mapStateToProps, { acceptPartnerBooking })(DriverBookingCardVIP)
)
