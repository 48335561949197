import { DISPATCH_VIEW, SALES_VIEW, SD_VIEW } from 'actions/bookingsListToggles'

const INITIAL_STATE = {
  salesView: false,
  sdView: false,
  dispatchView: false,
}

export default (state = INITIAL_STATE, action) => {
  const asArray = action.type?.split('_') || []
  const adjustFormat =
    asArray.length > 1
      ? asArray[0].toLowerCase() +
        asArray[1].charAt(0) +
        asArray[1].slice(1).toLowerCase()
      : asArray[0]?.toLowerCase() || ''
  if (state[adjustFormat]) return INITIAL_STATE
  switch (action.type) {
    case SD_VIEW:
      return { ...INITIAL_STATE, sdView: true }
    case SALES_VIEW:
      return { ...INITIAL_STATE, salesView: true }
    case DISPATCH_VIEW:
      return { ...INITIAL_STATE, dispatchView: true }
    default:
      return state
  }
}
