import React, { useEffect, useState } from 'react'
import { Button, Image, Modal } from 'react-bootstrap'
import { isMobile } from 'helpers'

const isMobileBrowser = isMobile()

const WhatsNewModalPartner = ({ show, onHide, values }) => {
  const [src, setSrc] = useState(null)

  const onClose = () => {
    onHide()
  }

  useEffect(() => {
    if (values && values.image && values.image.size) {
      const src =
        values.image && values.image
          ? window.URL.createObjectURL(values.image)
          : null
      setSrc(src)
    }
  }, [values.image])

  return (
    <div style={{ overflow: 'hidden' }}>
      <Modal
        size={isMobileBrowser ? 'md' : 'lg'}
        show={show}
        onHide={onClose}
        backdrop='static'
        className='whats-new-modal'
        dialogClassName='modal-size'
        backdropClassName='whats-new-backdrop'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Body>
          <div className='modal-panel'>
            <div className='modal-div'>
              {values.image && values.image.name && (
                <Image
                  src={src || values.image.name}
                  className='image'
                  width='100%'
                />
              )}
            </div>
            <div
              className='modal-container'
              style={
                (!values.ctaText || !values.ctaUrl) && isMobileBrowser
                  ? { paddingBottom: '45px' }
                  : {}
              }
            >
              <Modal.Header
                style={
                  (!values.ctaText || !values.ctaUrl) && !isMobileBrowser
                    ? { paddingTop: '30px' }
                    : {}
                }
              >
                <Modal.Title
                  id='example-modal-sizes-title-lg'
                  className='modal-title'
                >
                  <div className='close-btn-wrap'>
                    <Button
                      variant='link'
                      className={`${isMobileBrowser ? 'btn-link-mobile' : ''}`}
                      onClick={onClose}
                    >
                      <img src='/images/icons/close-icon-black.png' alt='' />
                    </Button>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <p
                className='whatsnew'
                style={
                  !values.ctaText || !values.ctaUrl ? { marginTop: 0 } : {}
                }
              >
                What's new
              </p>
              <p className='title'>{values.title}</p>
              <p className='description'>{values.description}</p>
              {values.ctaText && values.ctaUrl && (
                <div className='cta'>
                  <Button
                    href={values.ctaUrl}
                    target='_blank'
                    className='submit-btn'
                    variant='brown'
                  >
                    {values.ctaText}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default WhatsNewModalPartner
