import React, { useState, useEffect } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Col, Row } from 'react-bootstrap'
import { useGetTableData } from '../../../../../hooks'
import DefaultDriverDesktopLayout from '../../../../../components/layout/DefaultDriverDesktopLayout'
import {
  SmallUp,
  SmallDown,
} from '../../../../../components/Responsive/breakpoints'
import ConfirmationModal from 'components/confirmationModal'
import SupportModal from 'components/SupportModal'
import PartnerAcceptedModal from 'components/PartnerAcceptedModal'
import CompleteTripModal from 'components/CompleteTripModal'

const Support = ({ props, history, isCarRental, isMeetGreet }) => {
  const { token, bookingId } = useParams()

  let partnerType = localStorage.getItem('partnerType')
    ? localStorage.getItem('partnerType')
    : isCarRental
    ? 'rental'
    : isMeetGreet
    ? 'meetGreet'
    : 'chauffeur'

  const [supportNumber, setSupportNumber] = useState('')
  const [passengerNumber, setPassengerNumber] = useState('')
  const [passengerName, setPassengerName] = useState('')
  const [driverNumber, setDriverNumber] = useState(null)
  const [greeterNumber, setGreeterNumber] = useState(null)
  const [bookingAccepted, setBookingAccepted] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showNoShowModal, setshowNoShowModal] = useState(false)
  const [showReportModal, setShowReportModal] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  // const [showCompletedModal, setShowCompletedModal] = useState(false)
  const [showCompleteBookingModal, setShowCompleteBookingModal] = useState(
    false
  )
  const [noShowNote, setNoShowNote] = useState(null)

  const {
    data: { list },
    reloadList,
  } = useGetTableData(
    token && bookingId
      ? `external/partnerToken/${token}/${bookingId}/support`
      : `external/partnerToken/${props.token}/${props.bookingId}/support`
  )

  const {
    data: { list: booking },
    reloadList: reloadBooking,
  } = useGetTableData(`external/partnerBooking/${bookingId}/${token}`)

  const afterSubmissionFtn = () => {
    setIsSubmitted(true)
    setTimeout(
      function() {
        setIsSubmitted(false)
      }.bind(this),
      1500
    )
  }

  const { data } = useGetTableData(`external/partner/${token}`)

  let supplierId = null

  if (data && data.list && data.list[0]) {
    supplierId = data.list[0]._id
  }

  useEffect(() => {
    if (list && list.passenger && list.support) {
      setSupportNumber(list.support)
      setPassengerNumber(list.passenger)
      setPassengerName(list.passengerName)
      setBookingAccepted(list.isAccepted)
      setDriverNumber(list.driver)
      setGreeterNumber(list.greeter)
    }
  }, [list])

  const renderContent = () => {
    return (
      <>
        {!isSubmitted ? (
          <div
            className='d-flex flex-wrap driver-booking-container'
            style={{ margin: 0, maxWidth: '850px' }}
          >
            {(() => {
              const cards = []

              if (
                list &&
                list.isAccepted &&
                list.status !== 'cancellation_accepted_by_agent' &&
                list.status !== 'cancelled_by_agent' &&
                list.status !== 'completed'
              ) {
                cards.push(
                  <div
                    className='support-card'
                    key='call-passenger'
                    style={{ maxWidth: '410px' }}
                  >
                    <div className='support-icon-container'>
                      <img
                        className='support-img'
                        src='/images/icons/icon-call.png'
                        alt=''
                      />
                    </div>
                    <div className='d-flex flex-column mr-5'>
                      <span className='support-title mt-3 mb-2'>
                        Call {passengerName}
                      </span>
                      <span
                        className='support-message mb-4'
                        style={{ whiteSpace: 'pre' }}
                      >
                        Press the button below to get in touch with <br />
                        {passengerName}.
                      </span>
                    </div>
                    {list && list.canCall ? (
                      <a href={`tel:${passengerNumber}`}>
                        <Button
                          className='partner-btn-desktop-primary-sm select-vehicle'
                          variant='brown'
                          style={{
                            fontSize: '10px !important',
                            paddingLeft: '2.5rem',
                            paddingRight: '2.5rem',
                          }}
                        >
                          Call the passenger
                        </Button>
                      </a>
                    ) : (
                      <Button
                        className='partner-btn-desktop-primary-sm select-vehicle'
                        variant='brown'
                        onClick={() => setShowConfirmationModal(true)}
                        style={{
                          fontSize: '10px !important',
                          paddingLeft: '2.5rem',
                          paddingRight: '2.5rem',
                        }}
                      >
                        Call passenger
                      </Button>
                    )}
                  </div>
                )
              }

              if (greeterNumber) {
                cards.push(
                  <div
                    className='support-card'
                    key='call-greeter'
                    style={{
                      maxWidth: '410px',
                      minWidth: '410px',
                    }}
                  >
                    <div className='support-icon-container'>
                      <img
                        className='support-img'
                        src='/images/icons/icon-call.png'
                        alt=''
                      />
                    </div>
                    <div className='d-flex flex-column mr-5'>
                      <span className='support-title mt-3 mb-2'>
                        Call Greeter
                      </span>
                      <span
                        className='support-message mb-4'
                        style={{ whiteSpace: 'pre' }}
                      >
                        Press the button below to get in touch with <br />
                        the VIP Meet & Greet greeter.
                      </span>
                    </div>
                    <a href={`tel:${greeterNumber}`}>
                      <Button
                        className='partner-btn-desktop-primary-sm select-vehicle'
                        variant='brown'
                        style={{
                          paddingLeft: '2.5rem',
                          paddingRight: '2.5rem',
                        }}
                      >
                        Call greeter
                      </Button>
                    </a>
                  </div>
                )
              }

              cards.push(
                <div
                  className='support-card'
                  key='rolzo'
                  style={{
                    maxWidth: '410px',
                    minWidth: '410px',
                  }}
                >
                  <div className='support-icon-container '>
                    <img
                      className='support-img'
                      src='/images/icons/icon-call.png'
                      alt=''
                    />
                  </div>
                  <div className='d-flex flex-column mr-5'>
                    <span className='support-title mt-3 mb-2'>Call ROLZO</span>
                    <span
                      className='support-message mb-4'
                      style={{ whiteSpace: 'pre' }}
                    >
                      Press the button below to get in touch with the <br />
                      ROLZO Operations team.
                    </span>
                  </div>
                  <a href={`tel:${supportNumber}`}>
                    <Button
                      className='partner-btn-desktop-primary-sm select-vehicle'
                      variant='brown'
                      style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
                    >
                      Call ROLZO
                    </Button>
                  </a>
                </div>
              )

              if (
                partnerType !== 'rental' &&
                booking?.dispatchStatus !== 'completed' &&
                booking?.dispatchStatus !== 'incomplete'
              ) {
                cards.push(
                  <div
                    className='support-card'
                    key='no-show'
                    style={{
                      maxWidth: '410px',
                      minWidth: '410px',
                    }}
                  >
                    <div className='support-icon-container'>
                      <img
                        className='support-img'
                        src='/images/icons/icon-noshow.png'
                        alt=''
                        style={{ width: '22.8px', height: '22.8px' }}
                      />
                    </div>
                    <div className='d-flex flex-column mr-5'>
                      <span className='support-title mt-3 mb-2'>No-show</span>
                      <span
                        className='support-message mb-4'
                        style={{ whiteSpace: 'pre' }}
                      >
                        Press the button below if {passengerName} didn't <br />
                        show up and cannot be contacted.
                      </span>
                    </div>
                    <Button
                      className='partner-btn-desktop-primary-sm select-vehicle'
                      variant='brown'
                      onClick={() => setshowNoShowModal(true)}
                      style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
                    >
                      No-show
                    </Button>
                  </div>
                )
              }

              cards.push(
                <div
                  className='support-card'
                  key='report'
                  style={{
                    maxWidth: '410px',
                    minWidth: '410px',
                  }}
                >
                  <div className='support-icon-container'>
                    <img
                      className='support-img'
                      src='/images/icons/icon-report.png'
                      alt=''
                    />
                  </div>
                  <div className='d-flex flex-column mr-5'>
                    <span className='support-title mt-3 mb-2'>Report</span>
                    <span
                      className='support-message mb-4'
                      style={{ whiteSpace: 'pre', minHeight: '48px' }}
                    >
                      Press the button to report.
                      <hr style={{ border: 'none' }} />
                    </span>
                  </div>
                  <Button
                    className='partner-btn-desktop-primary-sm select-vehicle'
                    variant='brown'
                    onClick={() => setShowReportModal(true)}
                    style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
                  >
                    Report
                  </Button>
                </div>
              )

              // bring this back for vip m&g dispatch partner side
              // if (partnerType === 'meetGreet' && driverNumber) {
              //   cards.push(
              //     <div className='support-card' key='call-chauffeur'>
              //       <div className='support-icon-container'>
              //         <img
              //           className='support-img'
              //           src='/images/icons/icon-call.png'
              //           alt=''
              //         />
              //       </div>
              //       <div className='d-flex flex-column mr-5'>
              //         <span className='support-title mt-3 mb-2'>
              //           Call Chauffeur
              //         </span>
              //         <span
              //           className='support-message mb-4'
              //           style={{ whiteSpace: 'pre' }}
              //         >
              //           Press the button below to get in touch with <br />
              //           the chauffeur.
              //         </span>
              //       </div>
              //       <a href={`tel:${driverNumber}`}>
              //         <Button
              //           className='partner-btn-desktop-primary-sm select-vehicle'
              //           variant='brown'
              //         >
              //           Call chauffeur
              //         </Button>
              //       </a>
              //     </div>
              //   )
              // }

              const firstColumn = cards.slice(0, 3)
              const secondColumn = cards.slice(3)

              return (
                <>
                  <Col md={6} className='d-flex flex-column px-0'>
                    {firstColumn}
                  </Col>
                  <Col md={6} className='d-flex flex-column px-0'>
                    {secondColumn}
                  </Col>
                </>
              )
            })()}

            <ConfirmationModal
              onHide={() => {
                setShowConfirmationModal(false)
              }}
              show={showConfirmationModal}
              confirmationText={
                'You will be able to call the passenger within 60 minutes of the pick-up time.'
              }
              confirmationTitle={'Call passenger'}
              changeConfirmationTitle={true}
              partnerSupport
            />
            <SupportModal
              onHide={() => {
                setshowNoShowModal(false)
              }}
              show={showNoShowModal}
              modalTitle={'No-show'}
              modalLabel={'ADD A COMMENT (OPTIONAL)'}
              bookingId={bookingId}
              token={token}
              afterSubmission={() => {
                setShowCompleteBookingModal(true)
              }}
              setNoShowNote={setNoShowNote}
            />
            <SupportModal
              onHide={() => {
                setShowReportModal(false)
              }}
              show={showReportModal}
              modalTitle={'Report'}
              modalLabel={'ADD A COMMENT*'}
              bookingId={bookingId}
              token={token}
              afterSubmission={() => {
                afterSubmissionFtn()
              }}
            />
            <CompleteTripModal
              onHide={() => {
                setShowCompleteBookingModal(false)
              }}
              show={showCompleteBookingModal}
              bookingId={bookingId}
              supplierId={
                booking.supplierInfo && booking.supplierInfo.supplierId
                  ? booking.supplierInfo.supplierId
                  : null
              }
              isPartner={booking && booking.partnerOfBooking}
              number={booking.number || ''}
              afterAction={() => {
                setShowCompleteBookingModal(false)
                // setShowCompletedModal(true)
                // setTimeout(function() {
                //   setShowCompletedModal(false)
                // }, 1500)
                reloadBooking()
              }}
              noShow={true}
              noShowNote={noShowNote}
            />
          </div>
        ) : (
          <PartnerAcceptedModal isSubmitted={isSubmitted} />
        )}
      </>
    )
  }
  return (
    <>
      <SmallUp>
        <DefaultDriverDesktopLayout renderContent={renderContent} />
      </SmallUp>
      <SmallDown>
        {!isSubmitted ? (
          <>
            {showNoShowModal ? (
              <div style={{ height: '100vh' }}>
                <SupportModal
                  onHide={() => {
                    setshowNoShowModal(false)
                  }}
                  show={showNoShowModal}
                  modalTitle={'No-show'}
                  modalLabel={'ADD A COMMENT (OPTIONAL)'}
                  bookingId={bookingId}
                  token={token}
                  afterSubmission={() => {
                    setShowCompleteBookingModal(true)
                  }}
                  setNoShowNote={setNoShowNote}
                />
              </div>
            ) : showReportModal ? (
              <SupportModal
                onHide={() => {
                  setShowReportModal(false)
                }}
                show={showReportModal}
                modalTitle={'Report'}
                modalLabel={'ADD A COMMENT*'}
                bookingId={bookingId}
                token={token}
                afterSubmission={() => {
                  afterSubmissionFtn()
                }}
              />
            ) : (
              <>
                <div className='driver-booking-container-header-booking-support'>
                  <img
                    className='driver-back-icon mx-50%'
                    src='/images/icon-back.png'
                    alt=''
                    onClick={() => {
                      history.goBack()
                    }}
                  />
                  <span style={{ paddingTop: '0.2em' }}>Support</span>
                  <span style={{ paddingTop: '0.2em' }}></span>
                </div>
                <div
                  className='driver-booking-container'
                  style={{ margin: '5%' }}
                >
                  {list &&
                    list.isAccepted &&
                    list.status !== 'cancellation_accepted_by_agent' &&
                    list.status !== 'cancelled_by_agent' &&
                    list.status !== 'completed' && (
                      <div>
                        {list && list.canCall ? (
                          <a href={`tel:${passengerNumber}`}>
                            <Button
                              className='select-btn select-vehicle'
                              style={{
                                width: '100%',
                                marginBottom: '15px',
                                paddingLeft: '1.8rem',
                                paddingRight: '1.8rem',
                                fontWeight: '900',
                                fontSize: '16px',
                              }}
                              variant='brown'
                            >
                              CALL {passengerName.toUpperCase()}
                            </Button>
                          </a>
                        ) : (
                          <Button
                            className='select-btn select-vehicle'
                            style={{
                              width: '100%',
                              marginBottom: '15px',
                              paddingLeft: '1.8rem',
                              paddingRight: '1.8rem',
                              fontWeight: '900',
                              fontSize: '16px',
                            }}
                            variant='brown'
                            onClick={() => {
                              setShowConfirmationModal(true)
                            }}
                          >
                            CALL {passengerName.toUpperCase()}
                          </Button>
                        )}
                      </div>
                    )}
                  {partnerType === 'meetGreet' && driverNumber && (
                    <div>
                      <a href={`tel:${driverNumber}`}>
                        <Button
                          className='select-btn select-vehicle'
                          style={{
                            width: '100%',
                            marginBottom: '15px',
                            paddingLeft: '2.5rem',
                            paddingRight: '2.5rem',
                            fontWeight: '900',
                            fontSize: '16px',
                          }}
                          variant='brown'
                        >
                          CALL CHAUFFEUR
                        </Button>
                      </a>
                    </div>
                  )}
                  {greeterNumber && (
                    <div>
                      <a href={`tel:${greeterNumber}`}>
                        <Button
                          className='select-btn select-vehicle'
                          style={{
                            width: '100%',
                            marginBottom: '15px',
                            paddingLeft: '2.5rem',
                            paddingRight: '2.5rem',
                            fontWeight: '900',
                            fontSize: '16px',
                          }}
                          variant='brown'
                        >
                          CALL GREETER
                        </Button>
                      </a>
                    </div>
                  )}
                  <div>
                    <a href={`tel:${supportNumber}`}>
                      <Button
                        className='select-btn select-vehicle'
                        style={{
                          width: '100%',
                          marginBottom: '15px',
                          paddingLeft: '2.5rem',
                          paddingRight: '2.5rem',
                          fontWeight: '900',
                          fontSize: '16px',
                        }}
                        variant='brown'
                      >
                        CALL ROLZO
                      </Button>
                    </a>
                  </div>
                  {partnerType !== 'rental' &&
                    list &&
                    list.isAccepted &&
                    list.status !== 'cancellation_accepted_by_agent' &&
                    list.status !== 'cancelled_by_agent' &&
                    booking.dispatchStatus !== 'completed' &&
                    booking.dispatchStatus !== 'incomplete' && (
                      <div>
                        <Button
                          className='select-btn select-vehicle'
                          variant='brown'
                          onClick={() => {
                            setShowReportModal(false)
                            setshowNoShowModal(true)
                          }}
                          style={{
                            width: '100%',
                            paddingLeft: '2.5rem',
                            paddingRight: '2.5rem',
                            marginBottom: '15px',
                            fontWeight: '900',
                            fontSize: '16px',
                          }}
                        >
                          NO-SHOW
                        </Button>
                      </div>
                    )}
                  <div>
                    <Button
                      className='select-btn select-vehicle'
                      variant='brown'
                      onClick={() => {
                        setshowNoShowModal(false)
                        setShowReportModal(true)
                      }}
                      style={{
                        width: '100%',
                        paddingLeft: '2.5rem',
                        paddingRight: '2.5rem',
                        marginBottom: '15px',
                        fontWeight: '900',
                        fontSize: '16px',
                      }}
                    >
                      REPORT
                    </Button>
                  </div>
                </div>
                <ConfirmationModal
                  onHide={() => {
                    setShowConfirmationModal(false)
                  }}
                  show={showConfirmationModal}
                  confirmationText={`You will be able to call the passenger <br> within 60 minutes of the pick-up time.`}
                  confirmationTitle={'Call passenger'}
                  changeConfirmationTitle={true}
                  partnerSupport
                />
              </>
            )}
          </>
        ) : (
          <PartnerAcceptedModal isSubmitted={isSubmitted} />
        )}
      </SmallDown>
    </>
  )
}

const mapStateToProps = ({
  auth: { isCarRental = null, isMeetGreet = null },
}) => ({
  isCarRental,
  isMeetGreet,
})

export default withRouter(connect(mapStateToProps, {})(Support))
