import React, { useEffect, useState } from 'react'
import { Button, Image, Modal } from 'react-bootstrap'
import { isMobile } from 'helpers'

const isMobileBrowser = isMobile()

const WhatsNewModal = ({ show, onHide, values, onViewPopup = () => {} }) => {
  const [src, setSrc] = useState(null)
  const [activePopUpIndex, setActivePopUpIndex] = useState(0)

  const handleArrowClick = number => {
    const currentIndex = activePopUpIndex + number
    if (currentIndex === values.length || currentIndex === -1) return
    setActivePopUpIndex(currentIndex)
    onViewPopup(values[currentIndex]._id)
  }

  const handleActivePopUp = index => {
    setActivePopUpIndex(index)
    onViewPopup(values[index]._id)
  }

  useEffect(() => {
    if (show && values && values.length > 0) {
      onViewPopup(values[0]._id)
    }
  }, [show, values, onViewPopup])

  const onClose = popUpId => {
    if (popUpId) {
      onHide([popUpId])
    } else {
      onHide()
    }
  }

  useEffect(() => {
    if (
      values[activePopUpIndex] &&
      values[activePopUpIndex]?.imagePopup &&
      values[activePopUpIndex]?.imagePopup.size
    ) {
      const src =
        values[activePopUpIndex].imagePopup &&
        values[activePopUpIndex].imagePopup
          ? window.URL.createObjectURL(values[activePopUpIndex].imagePopup)
          : null
      setSrc(src)
    }
  }, [activePopUpIndex, values])
  return (
    <div style={{ overflow: 'hidden' }}>
      <Modal
        size={isMobileBrowser ? 'md' : 'lg'}
        show={show}
        onHide={onClose}
        backdrop='static'
        className='whats-new-modal'
        dialogClassName='modal-size'
        backdropClassName='whats-new-backdrop'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Body>
          {values[activePopUpIndex] && (
            <div className='modal-panel'>
              <div className='modal-div'>
                {values[activePopUpIndex].imagePopup &&
                  values[activePopUpIndex].imagePopup.name && (
                    <Image
                      src={src || values[activePopUpIndex].imagePopup.name}
                      className='image'
                      width='100%'
                    />
                  )}
              </div>
              <div
                className='modal-container'
                style={
                  (!values[activePopUpIndex].ctaText ||
                    !values[activePopUpIndex].ctaUrl) &&
                  isMobileBrowser
                    ? { paddingBottom: '45px' }
                    : {}
                }
              >
                <Modal.Header
                // style={
                //   (!values[activePopUpIndex].ctaText ||
                //     !values[activePopUpIndex].ctaUrl) &&
                //   !isMobileBrowser
                //     ? { paddingTop: '30px' }
                //     : {}
                // }
                >
                  <Modal.Title
                    id='example-modal-sizes-title-lg'
                    className='modal-title'
                  >
                    <div className='close-btn-wrap'>
                      <Button
                        variant='link'
                        className={`${
                          isMobileBrowser ? 'btn-link-mobile' : ''
                        }`}
                        onClick={onClose}
                      >
                        <img src='/images/icons/close-icon-black.png' alt='' />
                      </Button>
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <div
                  // style={{ marginBottom: '37px', minHeight: '300px' }}
                  className='d-flex flex-column'
                  style={{ flex: '1' }}
                >
                  <p
                    className='whatsnew'
                    style={
                      !values[activePopUpIndex].ctaText ||
                      !values[activePopUpIndex].ctaUrl
                        ? { marginTop: 0 }
                        : {}
                    }
                  >
                    What's new
                  </p>
                  <p className='title'>{values[activePopUpIndex].title}</p>
                  <p className='description'>
                    {values[activePopUpIndex].description}
                  </p>
                  {values[activePopUpIndex].ctaText &&
                    values[activePopUpIndex].ctaUrl && (
                      <div className='cta mt-auto'>
                        <Button
                          onClick={() => {
                            window.open(
                              values[activePopUpIndex].ctaUrl,
                              '_blank'
                            )
                            onClose(values[activePopUpIndex]._id)
                          }}
                          className='submit-btn'
                          variant='brown'
                        >
                          {values[activePopUpIndex].ctaText}
                        </Button>
                      </div>
                    )}
                </div>
              </div>
              {values.length > 1 && (
                <div className='slider-control-container'>
                  <button
                    type='button'
                    onClick={() => handleArrowClick(-1)}
                    className='arrow prev-arrow'
                    disabled={activePopUpIndex === 0}
                  >
                    {activePopUpIndex === 0 ? (
                      // <img
                      //   src='images/icons/prev-arrow-inactive.svg'
                      //   alt=''
                      //   className=''
                      // />
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          width='0.798844'
                          height='8.48531'
                          rx='0.399422'
                          transform='matrix(0.707107 -0.707107 -0.707107 -0.707107 10.0001 13)'
                          fill='#8B959E'
                        />
                        <rect
                          width='0.795775'
                          height='8.48531'
                          rx='0.397888'
                          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 10.5627 1.5625)'
                          fill='#8B959E'
                        />
                      </svg>
                    ) : (
                      // <img
                      //   src='images/icons/prev-arrow-active.svg'
                      //   alt=''
                      //   className=''
                      // />
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          width='0.798844'
                          height='8.48531'
                          rx='0.399422'
                          transform='matrix(0.707107 -0.707107 -0.707107 -0.707107 10.0001 13)'
                          fill='#0C0C0C'
                        />
                        <rect
                          width='0.795775'
                          height='8.48531'
                          rx='0.397888'
                          transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 10.5627 1.5625)'
                          fill='#0C0C0C'
                        />
                      </svg>
                    )}
                  </button>
                  <div className='dots-container'>
                    {values.map((val, index) => (
                      <span
                        onClick={() => handleActivePopUp(index)}
                        key={val._id}
                        class={`dot ${
                          activePopUpIndex === index ? 'active' : ''
                        }`}
                      />
                    ))}
                  </div>
                  <button
                    type='button'
                    onClick={() => handleArrowClick(1)}
                    className='arrow next-arrow'
                    disabled={activePopUpIndex === values.length - 1}
                  >
                    {activePopUpIndex === values.length - 1 ? (
                      // <img
                      //   src='images/icons/next-arrow-inactive.svg'
                      //   alt=''
                      //   className=''
                      // />
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          width='0.793285'
                          height='8.48531'
                          rx='0.396643'
                          transform='matrix(-0.707107 0.707107 0.707107 0.707107 5.56128 1)'
                          fill='#8B959E'
                        />
                        <rect
                          width='0.793704'
                          height='8.48531'
                          rx='0.396852'
                          transform='matrix(0.707107 0.707107 0.707107 -0.707107 5.00006 12.4395)'
                          fill='#8B959E'
                        />
                      </svg>
                    ) : (
                      // <img
                      //   src='images/icons/next-arrow-active.svg'
                      //   alt=''
                      //   className=''
                      // />
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          width='0.793285'
                          height='8.48531'
                          rx='0.396643'
                          transform='matrix(-0.707107 0.707107 0.707107 0.707107 5.56128 1)'
                          fill='#0C0C0C'
                        />
                        <rect
                          width='0.793704'
                          height='8.48531'
                          rx='0.396852'
                          transform='matrix(0.707107 0.707107 0.707107 -0.707107 5.00006 12.4395)'
                          fill='#0C0C0C'
                        />
                      </svg>
                    )}
                  </button>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default WhatsNewModal
