import { fileToBase64, getLabelFromValue } from '../helpers'
import { logger } from '../actions'
import { companyRoles, defaultUserStatus } from '../constants/index'
export class ClientUser {
  constructor({
    firstName = '',
    lastName = '',
    email = '',
    phone = '',
    roles,
  } = {}) {
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.phone = phone
    this.roles = roles ? roles.value : []
  }
}
export class ClientEditUser {
  constructor({
    firstName = '',
    lastName = '',
    email = '',
    phone = '',
    roles,
    image,
    password,
    userApiKey = '',
    userLabel = '',
  } = {}) {
    let roleFound = companyRoles.find(
      rolesObj => rolesObj.value[0] === roles[0]
    )
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.phone = phone
    this.roles = roleFound ? roleFound : companyRoles[0]
    this.password = password
    this.image = image
      ? {
          name: image,
        }
      : null
    this.userApiKey = userApiKey
    this.userLabel = userLabel
      ? {
          label: getLabelFromValue(defaultUserStatus, userLabel),
          value: userLabel,
        }
      : { label: 'INACTIVE', value: 'inactive' }
  }
}

export const companyClientUserFormData = (data = {}) => {
  if (Array.isArray(data.values)) {
    let values = data.values.map(item => new ClientUser(item))
    return { values: values, senderName: data.senderName }
  }

  return new ClientUser(data)
}

export const companyClientEditUserFormData = async ({ image, ...values }) => {
  try {
    let data = {
      ...values,
    }
    if (image && typeof image === 'object' && image.size) {
      const logoBase64 = await fileToBase64(image)
      data.image = logoBase64 || null
    }
    return data
  } catch (error) {
    logger({ fileName: 'companyClientUser', error: error })
    console.log(error)
    return {}
  }
}

export class CompanyClientPassenger {
  constructor({
    firstName = '',
    lastName = '',
    phone = '',
    drivingLicenceFrontFileId,
    drivingLicenceBackFileId,
    passportFileId,
    drivingLicenceFrontFileName,
    drivingLicenceBackFileName,
    passportFileName,
    isDriver,
  } = {}) {
    this.firstName = firstName
    this.lastName = lastName
    this.phone = phone
    this.drivingLicenceFrontFileId = drivingLicenceFrontFileId
      ? drivingLicenceFrontFileId
      : null
    this.drivingLicenceBackFileId = drivingLicenceBackFileId
      ? drivingLicenceBackFileId
      : null
    this.passportFileId = passportFileId ? passportFileId : null
    this.drivingLicenceFrontFileName = drivingLicenceFrontFileName
      ? drivingLicenceFrontFileName
      : null
    this.drivingLicenceBackFileName = drivingLicenceBackFileName
      ? drivingLicenceBackFileName
      : null
    this.passportFileName = passportFileName ? passportFileName : null
    this.isDriver = isDriver ? isDriver : false
  }
}
