const auth_alias = 'AUTH'

export const SET_USER = `${auth_alias}_SET_USER`
export const SET_MASQUERADE_USER = `${auth_alias}_SET_MASQUERADE_USER`
export const SET_USER_COMPANY = `${auth_alias}_SET_USER_COMPANY`
export const SET_USER_IP_LOCATION = `SET_USER_IP_LOCATION`
export const UPDATE_USER_INFO = `${auth_alias}_UPDATE_USER_INFO`
export const SET_PARTNER_COMPANY = `${auth_alias}_UPDATE_USER_INFO`
export const SET_IS_PARTNER = `${auth_alias}_IS_PARTNER`
export const REMOVE_VIEWED_POPUPS = `${auth_alias}_REMOVE_VIEWED_POPUPS`

export const CHECK_IS_UNIQ_MODEL = 'CHECK_IS_UNIQ_MODEL'
export const CHECK_IS_UNIQ_MODEL_SUCCESS = 'CHECK_IS_UNIQ_MODEL_SUCCESS'
export const CHECK_IS_UNIQ_MODEL_FAIL = 'CHECK_IS_UNIQ_MODEL_FAIL'

export const SET_MESSAGE_OPEN = 'SET_MESSAGE_OPEN'
export const SET_MESSAGE_CLOSE = 'SET_MESSAGE_CLOSE'

export const LOGGED_IN = `${auth_alias}_LOGGED_IN`
export const LOGGED_OUT = `${auth_alias}_LOGGED_OUT`

export const SET_CLIENT_CREDENTIALS = 'SET_CLIENT_CREDENTIALS'
export const SET_CLIENT_BUISNESS_DETAILS = 'SET_CLIENT_BUISNESS_DETAILS'
export const SET_CLIENT_PERSONAL_DETAILS = 'SET_CLIENT_PERSONAL_DETAILS'
export const CLEAR_CLIENT_DATA = 'CLEAR_CLIENT_DATA'

export const OPEN_CAR_DROP_DOWN = 'OPEN_CAR_DROP_DOWN'
export const CLOSE_CAR_DROP_DOWN = 'CLOSE_CAR_DROP_DOWN'

export const IS_VEHICLE_SEARCH_LATE = 'IS_VEHICLE_SEARCH_LATE'
export const IS_VEHICLE_SEARCH_NOT_LATE = 'IS_VEHICLE_SEARCH_NOT_LATE'

//admin tabs actions
export const OPERATIONS = 'OPERATIONS_TAB'
export const NETWORK = 'NETWORK_TAB'
export const FLEET = 'FLEET_TAB'
export const RATE = 'RATE_TAB'
export const FINANCE = 'FINANCE_TAB'
export const AUTOMATION = 'AUTOMATION_TAB'
export const ACCOUNT = 'ACCOUNT_TAB'
export const PAYOUT = 'PAYOYT_TAB'
export const ANALYTICS = 'ANALYTICS_TAB'
export const ROLZOLOCATIONS = 'ROLZOLOCATIONS_TAB'
