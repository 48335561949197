import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Col, Row } from 'react-bootstrap'
import { useGetTableData } from 'hooks'
import BankAccountModal from './BankAccountModal'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { requiredObject, requiredStringValidator } from 'form-validation'
import { addBankInfo, updateBankInfo, deleteBankInfo } from 'actions/payout'
import FormRadio from 'components/FormRadio'
import MatRaisedBtn from 'components/MatRaisedBtn'
import { SmallDown, SmallUp } from 'components/Responsive/breakpoints'
const BankAccounts = ({
  companyId = null,
  isPayout = false,
  addBankInfo,
  updateBankInfo,
  deleteBankInfo,
  isPartner = false,
  isPartnerPayoutTerm = null,
  isClient = false,
  isAdmin = false,
  isClientPayoutTerm = null,
  companyName = '',
}) => {
  const [showModal, setShowModal] = useState(false)
  const [accountInfo, setAccountInfo] = useState(null)
  const fetchUrl = companyId ? `bankingInfo/${companyId}` : null
  const {
    data: { list },
    isLoading,
    reloadList,
  } = useGetTableData(fetchUrl, {}, { limit: -1 })

  const validationSchema = Yup.object().shape({
    currency: requiredObject({
      message: 'Select a currency',
    }),
    country: requiredObject({
      message: 'Select a country',
    }),
    legalName: requiredStringValidator({ message: 'Enter a name' }),
  })

  const initialValues = accountInfo
    ? { ...accountInfo }
    : { currency: null, country: null, legalName: companyName }

  const addBankAccount = async values => {
    delete values.country.bounds
    if (accountInfo) {
      await updateBankInfo(values, companyId)
    } else if (companyId) {
      await addBankInfo(values, companyId)
    }

    reloadList()
    setShowModal(false)
  }
  const deleteAccount = async () => {
    await deleteBankInfo(companyId)
    reloadList()
  }

  // { label: 'Weekly', value: 'payout_term_weekly' },
  // { label: 'Monthly', value: 'payout_term_monthly' },
  // { label: 'Credit card', value: 'payout_instant_by_card' },
  // { label: 'PayPal', value: 'payout_instant_paypal' },

  const renderLabel = ([info], resetForm) => {
    let currency = info?.currency?.code
    let name = info.legalName
    if (isPartner) {
      if (isPartnerPayoutTerm === 'payout_term_weekly') {
        return (
          <>
            <div style={{ marginRight: '5rem' }}>
              <span className='payout-radio-label-partner'>{`Payment by bank transfer (weekly)`}</span>
            </div>
            <br />
            <div className='bank-info-partner'>
              <span
                style={{
                  fontSize: '14px',
                  marginBottom: '1rem',
                  fontWeight: 'bold',
                  lineHeight: '1.5',
                }}
              >
                {'Bank account'}
              </span>
              <span className='payout-radio-label'>{`${currency} | ${name}`}</span>
              {currency === 'USD' ? (
                <>
                  <span className='payout-radio-info'>
                    {`Account number: ${info.accountNumber} `}
                  </span>
                  <span className='payout-radio-info'>
                    {`Routing number: ${info.routingNumber}`}
                  </span>
                </>
              ) : currency === 'EUR' ? (
                <>
                  <span className='payout-radio-info'>
                    {`IBAN: ${info.iban} `}
                  </span>
                  <span className='payout-radio-info'>
                    {`Swift code: ${info.swiftCode}`}
                  </span>
                </>
              ) : currency === 'GBP' ? (
                <>
                  <span className='payout-radio-info'>
                    {`Account number: ${info.accountNumber} `}
                  </span>
                  <span className='payout-radio-info'>
                    {`Sort code: ${info.sortCode}`}
                  </span>
                </>
              ) : currency === 'CAD' ? (
                <>
                  {info?.institutionNumber ? (
                    <span className='payout-radio-info'>
                      {`Institution number: ${info.institutionNumber} `}
                    </span>
                  ) : (
                    <></>
                  )}
                  {info?.transitNumber ? (
                    <span className='payout-radio-info'>
                      {`Transit number: ${info.transitNumber} `}
                    </span>
                  ) : (
                    <></>
                  )}
                  {info?.accountNumber ? (
                    <span className='payout-radio-info'>
                      {`Account number: ${info.accountNumber} `}
                    </span>
                  ) : (
                    <></>
                  )}
                </>
              ) : currency === 'AUD' ? (
                <>
                  <span className='payout-radio-info'>
                    {`Account number: ${info.accountNumber} `}
                  </span>
                  <span className='payout-radio-info'>
                    {`BSB code: ${info.bsbCode} `}
                  </span>
                </>
              ) : (
                <>
                  <span className='payout-radio-info'>
                    {`IBAN: ${info.iban} `}
                  </span>
                  <span className='payout-radio-info'>
                    {`Swift code: ${info.swiftCode}`}
                  </span>
                </>
              )}
              <span style={{ marginTop: '20px' }}>
                <Button
                  className='inline-edit-btn btn-anchor btn-anchor mr-3'
                  variant='link'
                  onClick={() => setShowModal(true)}
                >
                  Edit
                </Button>
                <Button
                  className='inline-edit-btn btn-anchor-danger'
                  variant='link'
                  onClick={() => {
                    deleteAccount(companyId)
                    resetForm()
                  }}
                >
                  Remove
                </Button>
              </span>
            </div>
          </>
        )
      } else if (isPartnerPayoutTerm === 'payout_term_monthly') {
        return (
          <>
            <div style={{ marginRight: '5rem' }}>
              <span className='payout-radio-label-partner'>{`Payment by bank transfer (monthly)`}</span>
            </div>
            <br />
            <div className='bank-info-partner'>
              <span
                style={{
                  fontSize: '14px',
                  marginBottom: '1rem',
                  fontWeight: 'bold',
                  lineHeight: '1.5',
                }}
              >
                {'Bank account'}
              </span>
              <span className='payout-radio-label'>{`${currency} | ${name}`}</span>

              {currency === 'USD' ? (
                <>
                  <span className='payout-radio-info'>
                    {`Account number: ${info.accountNumber} `}
                  </span>
                  <span className='payout-radio-info'>
                    {`Routing number: ${info.routingNumber}`}
                  </span>
                </>
              ) : currency === 'EUR' ? (
                <>
                  <span className='payout-radio-info'>
                    {`IBAN: ${info.iban} `}
                  </span>
                  <span className='payout-radio-info'>
                    {`Swift code: ${info.swiftCode}`}
                  </span>
                </>
              ) : currency === 'GBP' ? (
                <>
                  <span className='payout-radio-info'>
                    {`Account number: ${info.accountNumber} `}
                  </span>
                  <span className='payout-radio-info'>
                    {`Sort code: ${info.sortCode}`}
                  </span>
                </>
              ) : currency === 'CAD' ? (
                <>
                  {info?.institutionNumber ? (
                    <span className='payout-radio-info'>
                      {`Institution number: ${info.institutionNumber} `}
                    </span>
                  ) : (
                    <></>
                  )}
                  {info?.transitNumber ? (
                    <span className='payout-radio-info'>
                      {`Transit number: ${info.transitNumber} `}
                    </span>
                  ) : (
                    <></>
                  )}
                  {info?.accountNumber ? (
                    <span className='payout-radio-info'>
                      {`Account number: ${info.accountNumber} `}
                    </span>
                  ) : (
                    <></>
                  )}
                </>
              ) : currency === 'AUD' ? (
                <>
                  <span className='payout-radio-info'>
                    {`Account number: ${info.accountNumber} `}
                  </span>
                  <span className='payout-radio-info'>
                    {`BSB code: ${info.bsbCode} `}
                  </span>
                </>
              ) : (
                <>
                  <span className='payout-radio-info'>
                    {`IBAN: ${info.iban} `}
                  </span>
                  <span className='payout-radio-info'>
                    {`Swift code: ${info.swiftCode}`}
                  </span>
                </>
              )}
              <span className='mt-3'>
                <Button
                  className='inline-edit-btn btn-anchor btn-anchor mr-3'
                  variant='link'
                  onClick={() => setShowModal(true)}
                >
                  Edit
                </Button>
                <Button
                  className='inline-edit-btn btn-anchor-danger'
                  variant='link'
                  onClick={() => {
                    deleteAccount(companyId)
                    resetForm()
                  }}
                >
                  Remove
                </Button>
              </span>
            </div>
          </>
        )
      } else if (isPartnerPayoutTerm === 'payout_instant_by_card') {
        return (
          <>
            <div style={{ marginRight: '5rem' }}>
              <span className='payout-radio-label-partner'>{`Payment by credit card`}</span>
            </div>
            <br />
            <div className='bank-info-partner'>
              <span
                style={{
                  fontSize: '14px',
                  marginBottom: '1rem',
                  fontWeight: 'bold',
                  lineHeight: '1.5',
                }}
              >
                {'Bank account'}
              </span>
              <span className='payout-radio-label'>{`${currency} | ${name}`}</span>
              {currency === 'USD' ? (
                <>
                  <span className='payout-radio-info'>
                    {`Account number: ${info.accountNumber} `}
                  </span>
                  <span className='payout-radio-info'>
                    {`Routing number: ${info.routingNumber}`}
                  </span>
                </>
              ) : currency === 'EUR' ? (
                <>
                  <span className='payout-radio-info'>
                    {`IBAN: ${info.iban} `}
                  </span>
                  <span className='payout-radio-info'>
                    {`Swift code: ${info.swiftCode}`}
                  </span>
                </>
              ) : currency === 'GBP' ? (
                <>
                  <span className='payout-radio-info'>
                    {`Account number: ${info.accountNumber} `}
                  </span>
                  <span className='payout-radio-info'>
                    {`Sort code: ${info.sortCode}`}
                  </span>
                </>
              ) : currency === 'CAD' ? (
                <>
                  {info?.institutionNumber ? (
                    <span className='payout-radio-info'>
                      {`Institution number: ${info.institutionNumber} `}
                    </span>
                  ) : (
                    <></>
                  )}
                  {info?.transitNumber ? (
                    <span className='payout-radio-info'>
                      {`Transit number: ${info.transitNumber} `}
                    </span>
                  ) : (
                    <></>
                  )}
                  {info?.accountNumber ? (
                    <span className='payout-radio-info'>
                      {`Account number: ${info.accountNumber} `}
                    </span>
                  ) : (
                    <></>
                  )}
                </>
              ) : currency === 'AUD' ? (
                <>
                  <span className='payout-radio-info'>
                    {`Account number: ${info.accountNumber} `}
                  </span>
                  <span className='payout-radio-info'>
                    {`BSB code: ${info.bsbCode} `}
                  </span>
                </>
              ) : (
                <>
                  <span className='payout-radio-info'>
                    {`IBAN: ${info.iban} `}
                  </span>
                  <span className='payout-radio-info'>
                    {`Swift code: ${info.swiftCode}`}
                  </span>
                </>
              )}
              <span className='mt-3'>
                <Button
                  className='inline-edit-btn btn-anchor btn-anchor mr-3'
                  variant='link'
                  onClick={() => setShowModal(true)}
                >
                  Edit
                </Button>
                <Button
                  className='inline-edit-btn btn-anchor-danger'
                  variant='link'
                  onClick={() => {
                    deleteAccount(companyId)
                    resetForm()
                  }}
                >
                  Remove
                </Button>
              </span>
            </div>
          </>
        )
      } else if (isPartnerPayoutTerm === 'payout_instant_paypal') {
        return (
          <>
            <div style={{ marginRight: '5rem' }}>
              <span className='payout-radio-label-partner'>{`Payment by PayPal`}</span>
            </div>
            <br />
            <div className='bank-info-partner'>
              <span
                style={{
                  fontSize: '14px',
                  marginBottom: '1rem',
                  fontWeight: 'bold',
                  lineHeight: '1.5',
                }}
              >
                {'Bank account'}
              </span>
              <span className='payout-radio-label'>{`${currency} | ${name}`}</span>
              <span className='payout-radio-info'>
                {`Account number: ${info.accountNumber} `}
              </span>
              <span className='payout-radio-info'>
                {`Routing number: ${info.routingNumber}`}
              </span>
              <span className='mt-3'>
                <Button
                  className='inline-edit-btn btn-anchor btn-anchor mr-3'
                  variant='link'
                  onClick={() => setShowModal(true)}
                >
                  Edit
                </Button>
                <Button
                  className='inline-edit-btn btn-anchor-danger'
                  variant='link'
                  onClick={() => {
                    deleteAccount(companyId)
                    resetForm()
                  }}
                >
                  Remove
                </Button>
              </span>
            </div>
          </>
        )
      }
    } else {
      if (currency === 'USD') {
        return (
          <div style={{ marginRight: '5rem' }}>
            <span className='payout-radio-label'>{`${currency} | ${name}`}</span>
            <br />
            <span className='payout-radio-info d-flex justify-content-between'>
              <span>
                {' '}
                {`Account number: ${info.accountNumber} | Routing number: ${info.routingNumber}`}
              </span>
              <span style={{ marginTop: '-0.5rem' }}>
                <Button
                  className='inline-edit-btn btn-anchor btn-anchor mr-3'
                  variant='link'
                  onClick={() => setShowModal(true)}
                >
                  Edit bank account
                </Button>
                <Button
                  className='inline-edit-btn btn-anchor-danger'
                  variant='link'
                  onClick={() => {
                    deleteAccount(companyId)
                    resetForm()
                  }}
                >
                  Remove bank account
                </Button>
              </span>
            </span>
          </div>
        )
      } else if (currency === 'EUR') {
        return (
          <div style={{ marginRight: '5rem' }}>
            <span className='payout-radio-label'>{`${currency} | ${name}`}</span>
            <br />
            <span className='payout-radio-info d-flex justify-content-between'>
              <span>
                {' '}
                {`IBAN: ${info.iban} | Swift code: ${info.swiftCode}`}
              </span>
              <span style={{ marginTop: '-0.5rem' }}>
                <Button
                  className='inline-edit-btn btn-anchor btn-anchor mr-3'
                  variant='link'
                  onClick={() => setShowModal(true)}
                >
                  Edit bank account
                </Button>
                <Button
                  className='inline-edit-btn btn-anchor-danger'
                  variant='link'
                  onClick={() => {
                    deleteAccount(companyId)
                    resetForm()
                  }}
                >
                  Remove bank account
                </Button>
              </span>
            </span>
          </div>
        )
      } else if (currency === 'GBP') {
        return (
          <div style={{ marginRight: '5rem' }}>
            <span className='payout-radio-label'>{`${currency} | ${name}`}</span>
            <br />
            <span className='payout-radio-info d-flex justify-content-between'>
              <span>
                {' '}
                {`Account number: ${info.accountNumber} | Sort code: ${info.sortCode}`}{' '}
              </span>
              <span style={{ marginTop: '-0.5rem' }}>
                <Button
                  className='inline-edit-btn btn-anchor btn-anchor mr-3'
                  variant='link'
                  onClick={() => setShowModal(true)}
                >
                  Edit bank account
                </Button>
                <Button
                  className='inline-edit-btn btn-anchor-danger'
                  variant='link'
                  onClick={() => {
                    deleteAccount(companyId, resetForm)
                    resetForm()
                  }}
                >
                  Remove bank account
                </Button>
              </span>
            </span>
          </div>
        )
      }
      if (currency === 'CAD') {
        return (
          <>
            {info?.institutionNumber ? (
              <span className='payout-radio-info'>
                {`Institution number: ${info.institutionNumber} `}
              </span>
            ) : (
              <></>
            )}
            {info?.transitNumber ? (
              <span className='payout-radio-info'>
                {`Transit number: ${info.transitNumber} `}
              </span>
            ) : (
              <></>
            )}
            {info?.accountNumber ? (
              <span className='payout-radio-info'>
                {`Account number: ${info.accountNumber} `}
              </span>
            ) : (
              <></>
            )}
          </>
        )
      }
      if (currency === 'AUD') {
        return (
          <>
            <span className='payout-radio-info'>
              {`Account number: ${info.accountNumber} `}
            </span>
            <span className='payout-radio-info'>
              {`BSB code: ${info.bsbCode} `}
            </span>
          </>
        )
      } else {
        return (
          <div style={{ marginRight: '5rem' }}>
            <span className='payout-radio-label'>{`${currency} | ${name}`}</span>
            <br />
            <span className='payout-radio-info d-flex justify-content-between'>
              <span>
                {' '}
                {`IBAN: ${info.iban} | Swift code: ${info.swiftCode}`}{' '}
              </span>
              <span style={{ marginTop: '-0.5rem' }}>
                <Button
                  className='inline-edit-btn btn-anchor btn-anchor mr-3'
                  variant='link'
                  onClick={() => setShowModal(true)}
                >
                  Edit bank account
                </Button>
                <Button
                  className='inline-edit-btn btn-anchor-danger'
                  variant='link'
                  onClick={() => {
                    deleteAccount(companyId, resetForm)
                    resetForm()
                  }}
                >
                  Remove bank account
                </Button>
              </span>
            </span>
          </div>
        )
      }
    }
  }

  const renderClient = ([info], resetForm) => {
    let currency = info.currency ? info.currency.code : 'currency_usd'
    let name = info.legalName
    // console.log('info.bsbCode', info)
    return (
      <>
        <div
          style={isAdmin ? { width: '480px' } : {}}
          className='bank-info-client'
        >
          <span
            style={{
              fontSize: '14px',
              marginBottom: '1rem',
              fontWeight: 'bold',
              lineHeight: '1.5',
            }}
          >
            {'Bank account'}
          </span>
          <span className='payout-radio-label'>{`${currency} | ${name}`}</span>

          <SmallUp>
            <Row style={{ rowGap: '8px' }}>
              {info?.accountNumber ? (
                <Col md='6' className=''>
                  <span
                    style={{ color: '#636D77', fontSize: '12px' }}
                    className='payout-radio-info-title'
                  >
                    ACCOUNT NUMBER
                  </span>
                  <div className='payout-radio-info-value'>
                    {info.accountNumber}
                  </div>
                </Col>
              ) : (
                <></>
              )}
              {info?.routingNumber ? (
                <Col md='6'>
                  <span
                    style={{ color: '#636D77', fontSize: '12px' }}
                    className='payout-radio-info-title'
                  >
                    ROUTING NUMBER
                  </span>
                  <div className='payout-radio-info-value'>
                    {info.routingNumber}
                  </div>
                </Col>
              ) : (
                <></>
              )}
              {info?.iban ? (
                <Col md='6' className=''>
                  <span
                    style={{ color: '#636D77', fontSize: '12px' }}
                    className='payout-radio-info-title'
                  >
                    IBAN
                  </span>
                  <div className='payout-radio-info-value'>{info.iban}</div>
                </Col>
              ) : (
                <></>
              )}
              {info?.swiftCode ? (
                <Col md='6'>
                  <span
                    style={{ color: '#636D77', fontSize: '12px' }}
                    className='payout-radio-info-title'
                  >
                    SWIFT
                  </span>
                  <div className='payout-radio-info-value'>
                    {info.swiftCode}
                  </div>
                </Col>
              ) : (
                <></>
              )}
              {info?.bsbCode ? (
                <Col md='6'>
                  <span
                    style={{ color: '#636D77', fontSize: '12px' }}
                    className='payout-radio-info-title'
                  >
                    BSB CODE
                  </span>
                  <div className='payout-radio-info-value'>{info.bsbCode}</div>
                </Col>
              ) : (
                <></>
              )}
              {info?.institutionNumber ? (
                <Col md='6'>
                  <span
                    style={{ color: '#636D77', fontSize: '12px' }}
                    className='payout-radio-info-title'
                  >
                    INSTITUTION NUMBER
                  </span>
                  <div className='payout-radio-info-value'>
                    {info.institutionNumber}
                  </div>
                </Col>
              ) : (
                <></>
              )}
              {info?.transitNumber ? (
                <Col md='6'>
                  <span
                    style={{ color: '#636D77', fontSize: '12px' }}
                    className='payout-radio-info-title'
                  >
                    TRANSIT NUMBER
                  </span>
                  <div className='payout-radio-info-value'>
                    {info.transitNumber}
                  </div>
                </Col>
              ) : (
                <></>
              )}
              {info?.sortCode ? (
                <Col md='6'>
                  <span
                    style={{ color: '#636D77', fontSize: '12px' }}
                    className='payout-radio-info-title'
                  >
                    SORT CODE
                  </span>
                  <div className='payout-radio-info-value'>{info.sortCode}</div>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </SmallUp>
          <SmallDown>
            <>
              {info?.accountNumber ? (
                <div className='mr-3'>
                  <span
                    style={{ color: '#636D77', fontSize: '12px' }}
                    className='payout-radio-info-title'
                  >
                    ACCOUNT NUMBER
                  </span>
                  <div
                    style={{ whiteSpace: 'nowrap' }}
                    className='payout-radio-info-value'
                  >
                    {info.accountNumber}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {info?.routingNumber ? (
                <div>
                  <span
                    style={{ color: '#636D77', fontSize: '12px' }}
                    className='payout-radio-info-title'
                  >
                    ROUTING NUMBER
                  </span>
                  <div
                    style={{ whiteSpace: 'nowrap' }}
                    className='payout-radio-info-value'
                  >
                    {info.routingNumber}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {info?.iban ? (
                <div className='mr-3'>
                  <span
                    style={{ color: '#636D77', fontSize: '12px' }}
                    className='payout-radio-info-title'
                  >
                    IBAN
                  </span>
                  <div
                    style={{ whiteSpace: 'nowrap' }}
                    className='payout-radio-info-value'
                  >
                    {info.iban}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {info?.swiftCode ? (
                <div>
                  <span
                    style={{ color: '#636D77', fontSize: '12px' }}
                    className='payout-radio-info-title'
                  >
                    SWIFT
                  </span>
                  <div
                    style={{ whiteSpace: 'nowrap' }}
                    className='payout-radio-info-value'
                  >
                    {info.swiftCode}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {info?.sortCode ? (
                <div>
                  <span
                    style={{ color: '#636D77', fontSize: '12px' }}
                    className='payout-radio-info-title'
                  >
                    Sort
                  </span>
                  <div className='payout-radio-info-value'>{info.sortCode}</div>
                </div>
              ) : (
                <></>
              )}
              {info?.institutionNumber ? (
                <div>
                  <span
                    style={{ color: '#636D77', fontSize: '12px' }}
                    className='payout-radio-info-title'
                  >
                    INSTITUTION NUMBER
                  </span>
                  <div className='payout-radio-info-value'>
                    {info.institutionNumber}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {info?.transitNumber ? (
                <div>
                  <span
                    style={{ color: '#636D77', fontSize: '12px' }}
                    className='payout-radio-info-title'
                  >
                    TRANSIT NUMBER
                  </span>
                  <div className='payout-radio-info-value'>
                    {info.transitNumber}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {info?.bsbCode ? (
                <div>
                  <span
                    style={{ color: '#636D77', fontSize: '12px' }}
                    className='payout-radio-info-title'
                  >
                    BSB CODE
                  </span>
                  <div className='payout-radio-info-value'>{info.bsbCode}</div>
                </div>
              ) : (
                <></>
              )}
            </>
          </SmallDown>

          <span className='mt-3'>
            <Button
              className='inline-edit-btn btn-anchor btn-anchor mr-3'
              variant='link'
              onClick={() => setShowModal(true)}
            >
              Edit
            </Button>
            <Button
              className='inline-edit-btn btn-anchor-danger'
              variant='link'
              onClick={() => {
                deleteAccount(companyId)
                resetForm()
              }}
            >
              Remove
            </Button>
          </span>
        </div>
      </>
    )
  }

  const onlyRenderLabel = payoutTerm => {
    if (payoutTerm === 'payout_term_weekly') {
      return (
        <>
          <div style={{ marginRight: '5rem' }}>
            <span className='payout-radio-label-partner'>{`Payment by bank transfer (weekly)`}</span>
          </div>
        </>
      )
    } else if (payoutTerm === 'payout_term_monthly') {
      return (
        <>
          <div style={{ marginRight: '5rem' }}>
            <span className='payout-radio-label-partner'>{`Payment by bank transfer (monthly)`}</span>
          </div>
        </>
      )
    } else if (payoutTerm === 'payout_instant_by_card') {
      return (
        <>
          <div style={{ marginRight: '5rem' }}>
            <span className='payout-radio-label-partner'>{`Payment by credit card`}</span>
          </div>
        </>
      )
    } else if (payoutTerm === 'payout_instant_paypal') {
      return (
        <>
          <div style={{ marginRight: '5rem' }}>
            <span className='payout-radio-label-partner'>{`Payment by PayPal`}</span>
          </div>
        </>
      )
    }
  }

  useEffect(() => {
    if (list && list.length) {
      setAccountInfo(...list)
    } else setAccountInfo(null)
  }, [list])

  return (
    <>
      {/* {!isPayout && !isClient ? (
        <h4 className='sub-header'> Bank accounts </h4>
      ) : (
        <></>
      )} */}
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
        isInitialValid={true}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, resetForm, ...formikProps }) => {
          return (
            <Form noValidate>
              {!isLoading ? (
                <>
                  {list && list.length ? (
                    <>
                      {isClient ? (
                        renderClient(list, resetForm)
                      ) : (
                        <FormRadio
                          name='noAvailability'
                          id='noAvailability'
                          className='payout-radio'
                          label={renderLabel(list, resetForm)}
                          labelClass='options'
                          checked={true}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {isPartner ? (
                        <FormRadio
                          name='noAvailability'
                          id='noAvailability'
                          className='payout-radio mb-4'
                          label={onlyRenderLabel(isPartnerPayoutTerm)}
                          labelClass='options'
                          checked={true}
                        />
                      ) : (
                        <></>
                      )}
                      <div className='h5 mt-2 mb-4'>
                        {/* <MatRaisedBtn
                          title={'Add bank account'}
                          onClick={() => setShowModal(true)}
                        /> */}

                        <button
                          onClick={() => setShowModal(true)}
                          className={`btn btn-anchor btn-search`}
                          type='button'
                        >
                          {'Add bank account'}
                        </button>
                      </div>
                    </>
                  )}
                  {showModal ? (
                    <BankAccountModal
                      onHide={() => {
                        setShowModal(false)
                        resetForm()
                      }}
                      values={values}
                      handleFunction={() =>
                        formikProps.validateForm().then(errors => {
                          // console.log('errors', errors)
                          if (Object.keys(errors).length === 0) {
                            // If there are no errors, proceed with the original handleFunction logic
                            addBankAccount(values)
                            formikProps.setSubmitting(false)
                            formikProps.resetForm()
                          } else {
                            // If there are errors, mark all fields as touched to show error messages
                            const touchedFields = Object.keys(errors).reduce(
                              (acc, curr) => {
                                acc[curr] = true
                                return acc
                              },
                              {}
                            )
                            formikProps.setTouched(touchedFields)
                            // If there are errors, you might want to handle them, e.g., by showing a message to the user
                            console.error('Validation errors:', errors)
                            formikProps.setSubmitting(false)
                          }
                        })
                      }
                      show={showModal}
                      formikProps={formikProps}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

// export default BankAccounts
export default withRouter(
  connect(null, { addBankInfo, updateBankInfo, deleteBankInfo })(BankAccounts)
)
