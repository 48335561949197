import React from 'react'
import { useParams, withRouter } from 'react-router'
import { useGetTableData } from '../.././../../../hooks'
import DefaultDriverLayout from '../../../../../components/layout/DefaultDriverLayout'
import { connect } from 'react-redux'

const Chauffeurs = ({ isCarRental, isMeetGreet }) => {
  const { token } = useParams()
  let partnerType = localStorage.getItem('partnerType')
    ? localStorage.getItem('partnerType')
    : isCarRental
    ? 'rental'
    : isMeetGreet
    ? 'meetGreet'
    : 'chauffeur'

  const {
    data: { list },
    isLoading,
    onFiltersChange,
    filters,
  } = useGetTableData(
    partnerType === 'rental'
      ? `external/agent/${token}`
      : partnerType === 'meetGreet'
      ? `external/greeter/${token}`
      : `external/chauffeur/${token}`
  )

  return (
    <DefaultDriverLayout
      data={list}
      isAccountLoading={isLoading}
      onFiltersChange={onFiltersChange}
      filters={filters}
    />
  )
}

const mapStateToProps = ({
  auth: { isCarRental = null, isMeetGreet = null },
}) => ({
  isCarRental,
  isMeetGreet,
})

export default withRouter(connect(mapStateToProps, null)(Chauffeurs))
