import React, { useEffect, useState } from 'react'
import { Form, Image } from 'react-bootstrap'
import { getIn, setIn } from 'formik'
import _ from 'lodash'

const { Control, Group, Label } = Form

export default function FormInputFile({
  field = {},
  form: {
    touched = {},
    errors = {},
    setFieldValue = () => {},
    setTouched = () => {},
  } = {},
  icon,
  groupProps = {},
  controlId,
  needPreview = false,
  previewOnly = false,
  disabled = false,
  handleChange,
  customControlClassName = null,
  renderField = null,
  chatPaperclip = false,
  isAdminChat = false,
  multiple = false,
  previewWidth,
  previewHeight,
  needPdfPreview = false,
  onlyPdfIconPreview = false,
  hideField = false,
  clientLogo = false,
  isPassengerInfo,
  isUploadFileAdmin = false,
  isCDNFiles = false,
  removeOption = false,
  isCompanyProfile = false,
  ...props
}) {
  const showError = !!getIn(touched, field.name) && !!getIn(errors, field.name)
  const [src, setSrc] = useState(null)

  useEffect(() => {
    const src =
      field.value && field.value.size
        ? window.URL.createObjectURL(field.value)
        : null
    setSrc(src)
  }, [field.value])

  const onChange = e => {
    if (multiple) {
      if (e.target.files.length > 1) {
        setFieldValue(field.name, [...e.target.files])
        if (handleChange) {
          handleChange([...e.target.files])
        }
      } else {
        if (isCDNFiles) {
          setFieldValue(field.name, [e.target.files[0]])
        } else {
          setFieldValue(field.name, [...field.value, e.target.files[0]])
        }
        if (handleChange) {
          handleChange(e.target.files[0])
        }
      }
    } else {
      setFieldValue(field.name, e.target.files[0])
      if (handleChange) {
        handleChange(e.target.files[0])
      }
    }
  }

  const onClick = () => {
    const _touched = setIn({ ...touched }, field.name, true)
    setTouched(_touched)
  }

  const previewSrc = field && field.value ? field.value.name : ''

  const renderPreview = () => {
    if (needPreview) {
      if (
        field.value &&
        field.value.type === 'application/pdf' &&
        needPdfPreview &&
        field.value.size
      ) {
        return (
          <div className='pdf-preview'>
            <img src='/images/icons/pdf.svg' alt='' />
            {!onlyPdfIconPreview && previewSrc}
          </div>
        )
      }
      if (field.value && field.value.size) {
        return (
          <div className='removable-image'>
            <Image
              src={src || previewSrc}
              width={previewWidth || '100%'}
              height={previewHeight || '100%'}
            />
          </div>
        )
      }
      if (field.value && field.value.name) {
        return (
          <div className='removable-image'>
            <Image
              src={field.value.name}
              width={previewWidth || '100%'}
              height={previewHeight || '100%'}
            />
          </div>
        )
      }
    }
  }
  const renderPreviewMultiple = () => {
    if (needPreview && field && field.value) {
      return field.value.map((value, index) => {
        let previewSrc = value ? value.name : ''
        if (
          value &&
          value.type === 'application/pdf' &&
          needPdfPreview &&
          value.size
        ) {
          return (
            <div className='pdf-preview'>
              <img src='/images/icons/pdf.svg' alt='' />
              {!onlyPdfIconPreview && previewSrc}
            </div>
          )
        }
        if (value && value.size) {
          return (
            <div className='removable-image'>
              {/* <Image
                src={src || previewSrc}
                width={previewWidth || '100%'}
                height={previewHeight || '100%'}
              /> */}
              <p>{previewSrc}</p>
            </div>
          )
        }
        if (value && value.name) {
          return (
            <div className='removable-image'>
              {/* <Image
                src={value.name}
                width={previewWidth || '100%'}
                height={previewHeight || '100%'}
              /> */}
              <p>{previewSrc}</p>
            </div>
          )
        }
      })
    }
  }
  return (
    <Group
      style={
        hideField
          ? { display: 'none' }
          : isPassengerInfo
          ? { position: 'sticky' }
          : isUploadFileAdmin
          ? { width: '50%', marginLeft: 'auto', marginRight: 'auto' }
          : {}
      }
      controlId={controlId || field.name}
    >
      {!!props.label && (
        <Label
          className='form-label'
          style={removeOption ? { display: 'inline' } : {}}
        >
          {props.label}
        </Label>
      )}
      {removeOption && field.value && (
        <span
          className='text-danger'
          style={{ fontSize: '11.5px', paddingLeft: '5px', cursor: 'pointer' }}
          onClick={() => {
            setFieldValue(field.name, null)
            setSrc(null)
          }}
        >
          Remove
        </span>
      )}
      <div
        className={`custom-file ${
          previewOnly && field.value ? 'no-visible' : ''
        }
          ${isAdminChat ? 'admin-chat' : ''}
          ${chatPaperclip ? 'chat-paperclip' : ''}
          ${disabled ? 'disabled' : ''}
          ${props.inputClass ? props.inputClass : ''}
          ${clientLogo ? 'pl-0' : ''}
          ${isCompanyProfile ? 'company-logo-white' : ''}
          `}
      >
        <Control
          type='file'
          {...field}
          onChange={onChange}
          value=''
          onClick={onClick}
          {...props}
          isInvalid={showError}
          className='custom-file-input'
          disabled={!!disabled}
          multiple={!!multiple}
        />
        <label
          className={
            customControlClassName
              ? customControlClassName
              : 'custom-file-label'
          }
          htmlFor={field.name}
        >
          {!!renderField ? (
            renderField({ previewSrc: src || previewSrc })
          ) : (
            <>
              {field.value && field.value.name ? (
                ''
              ) : (
                <img src='/images/icons/add.svg' alt='' />
              )}
              {/* <span className='file-name'>
                {field.value && field.value.name
                  ? field.value.name
                  : 'Select file'}
              </span> */}
              {field.value && field.value.name ? (
                <span className='file-name'>{field.value.name}</span>
              ) : (
                <span className='file-name' style={{ marginTop: '0.15rem' }}>
                  {'Select file'}
                </span>
              )}
            </>
          )}
        </label>
      </div>
      <Control.Feedback
        className={showError ? 'd-block' : 'd-none'}
        type='invalid'
      >
        {getIn(errors, field.name)}
      </Control.Feedback>
      {!renderField && renderPreview()}

      {isCDNFiles && renderPreviewMultiple()}
    </Group>
  )
}
