import React, { useState } from 'react'
export const Toggle = ({ label, toggled, onClick, className, isPartner }) => {
  const [isToggled, toggle] = useState(toggled)

  const callback = () => {
    toggle(!isToggled)
    onClick(!isToggled)
  }

  return (
    <div
      className={isPartner ? 'toggleSwitch mt5px ' : 'toggleSwitch ml30 mt5px'}
    >
      <label>
        <input type='checkbox' checked={toggled} onClick={callback} />
        <span />
      </label>
      {label && <a className={`${className || ''}`}>{label}</a>}
    </div>
  )
}
