import React, { useEffect, useState } from 'react'
import { Modal, Carousel, Button } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

const WhatsNewModalMobile = ({
  show,
  onHide,
  values,
  onViewPopup = () => {},
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  useEffect(() => {
    if (show && values && values.length > 0) {
      onViewPopup(values[0]._id)
    }
  }, [show, values, onViewPopup])

  const handleSelect = selectedIndex => {
    setActiveIndex(selectedIndex)
    onViewPopup(values[selectedIndex]._id)
  }
  const onClose = () => {
    onHide()
  }

  return show ? (
    <div
      style={{
        position: 'relative',
        backgroundSize: 'cover',
        height: '100vh',
        width: '350px',
        maxWidth: '350px',
        margin: '0 auto',
      }}
    >
      <Modal
        size={'md'}
        show={show}
        onHide={onClose}
        backdrop='static'
        className='whats-new-modal-mobile'
        dialogClassName='modal-size'
        backdropClassName='whats-new-backdrop'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <div style={{ maxWidth: '100%', height: 'fit-content' }}>
          <Carousel
            activeIndex={activeIndex}
            onSelect={handleSelect}
            wrap={false}
            indicators={values.length > 1}
            controls={false}
            interval={null}
            touch={true}
            style={{
              // overflow: 'hidden',
              backgroundColor: '#f3f4f580',
              // height: 'fit-content',
              // maxHeight: '100%'
            }}
          >
            {values.map(item => {
              let src = ''
              if (item && item?.imagePopup && item?.imagePopup.size) {
                src =
                  item?.imagePopup && item?.imagePopup
                    ? window.URL.createObjectURL(item.imagePopup.name)
                    : null
              }
              return (
                <Carousel.Item key={item._id}>
                  <div
                    style={{
                      backgroundColor: 'white',
                      padding: '0',
                      overflow: 'hidden',
                      height: 'fit-content',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div style={{ height: '310px' }}>
                      <img
                        src={src || item?.imagePopup?.name}
                        alt=''
                        style={{
                          width: '100%',
                          height: '310px',
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                    <div
                      style={{
                        padding: '16px',
                        height: 'fit-content',
                        display: 'flex',
                        flexDirection: 'column',
                        // minHeight:'300px',
                        maxHeight: '100%',
                      }}
                    >
                      <Modal.Title
                        id='example-modal-sizes-title-lg'
                        className='modal-title'
                      >
                        <div
                          style={{ marginBottom: '16px' }}
                          className='d-flex align-items-center justify-content-end '
                        >
                          <Button
                            onClick={onClose}
                            variant='link'
                            style={{
                              width: '12px',
                              height: '12px',
                            }}
                          >
                            <img
                              style={{ width: '12px', height: '12px' }}
                              src='/images/icons/close-icon-black.png'
                              alt=''
                            />
                          </Button>
                        </div>
                      </Modal.Title>
                      <div
                        style={{ position: 'relative', marginBottom: '16px' }}
                      >
                        <h5 className='header'>What’s new</h5>
                        <p className='title'>{item.title}</p>
                        <p className='description'>{item.description}</p>
                      </div>
                      {item.ctaText && item.ctaUrl && (
                        <div className='cta'>
                          <Button
                            href={item.ctaUrl}
                            target='_blank'
                            className='submit-btn d-flex align-items-center justify-content-center'
                            variant='brown'
                          >
                            {item.ctaText}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Carousel.Item>
              )
            })}
          </Carousel>
        </div>
      </Modal>
      <div
        style={{
          textAlign: 'center',
          position: 'absolute',
          bottom: '20px',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
          }}
        >
          {values.map((_, index) => (
            <span
              key={index}
              style={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: index === activeIndex ? '#666' : '#ddd',
                display: 'inline-block',
                cursor: 'pointer',
              }}
              onClick={() => handleSelect(index)}
            />
          ))}
        </div>
      </div>
    </div>
  ) : (
    ''
  )
}

export default WhatsNewModalMobile
