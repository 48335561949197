import { companyClientFormData } from '../models/companyClient'
import { companySupplierFormData } from '../models/companySupplier'
import {
  companyClientEditUserFormData,
  companyClientUserFormData,
} from '../models/companyClientUser'
import { createOrEditItem, deleteItem } from './form'
import { connectedApiService } from 'index'
import { individualEditUserFormData } from 'models/individuals'

export const createClient = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'company/client',
      formatFormData: companyClientFormData,
      successMessage: 'Client was successfully created',
    })
  )

export const createSupplier = values => async dispatch =>
  dispatch(
    createOrEditItem(values, {
      endpoint: 'company/supplier',
      formatFormData: companySupplierFormData,
      successMessage: 'Supplier was successfully created',
    })
  )

export const editClient = (values, id, successMsg = null) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `company/client/${id}`,
      formatFormData: companyClientFormData,
      successMessage: successMsg
        ? successMsg
        : 'Client was successfully updated',
    })
  )
export const editBenefits = (values, id, modeType) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `company/client/${id}/benefits/${modeType}`,
      // formatFormData: companyClientFormData,
      successMessage: 'Benefits was successfully updated',
    })
  )

export const editSupplier = (values, id) => async dispatch =>
  dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `company/supplier/${id}`,
      formatFormData: companySupplierFormData,
      successMessage: 'Supplier was successfully updated',
    })
  )

export const toggleClientStatus = (id, active = false) => async dispatch =>
  await dispatch(
    createOrEditItem(
      { active },
      {
        isEdit: true,
        endpoint: `company/client/${id}`,
        successMessage: 'Client was successfully updated',
      }
    )
  )

export const toggleSupplierStatus = (id, active = false) => async dispatch =>
  dispatch(
    createOrEditItem(
      { active },
      {
        isEdit: true,
        endpoint: `company/supplier/${id}`,
        successMessage: 'Supplier was successfully updated',
      }
    )
  )

export const createClientUser = (clientId, values) => async dispatch =>
  dispatch(
    createOrEditItem(values, {
      endpoint: `company/client/${clientId}/createUser`,
      formatFormData: companyClientEditUserFormData,
      successMessage: 'Member was successfully created',
      hideErrorMessage: true,
    })
  )

export const inviteClientUsers = (
  clientId,
  values,
  senderName
) => async dispatch =>
  dispatch(
    createOrEditItem(
      { values: values, senderName: senderName },
      {
        endpoint: `company/client/${clientId}/user`,
        formatFormData: companyClientUserFormData,
        successMessage: 'Members were successfully invited',
        hideErrorMessage: true,
      }
    )
  )

export const editClientUser = (clientId, userId, values) => dispatch =>
  dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `company/client/${clientId}/companyUser/${userId}`,
      formatFormData: companyClientEditUserFormData,
      successMessage: 'Member successfully updated',
    })
  )
export const editB2CUser = (userId, values) => dispatch =>
  dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `individual/user/${userId}`,
      formatFormData: individualEditUserFormData,
      successMessage: 'Member successfully updated',
    })
  )
export const toggleClientUserStatus = (
  clientId,
  userId,
  active = false
) => async dispatch =>
  dispatch(
    createOrEditItem(
      { active },
      {
        isEdit: true,
        endpoint: `company/client/${clientId}/companyUser/${userId}`,
        successMessage: 'Client user was successfully updated',
      }
    )
  )
export const verifyClientUser = userId => async dispatch =>
  dispatch(
    createOrEditItem(
      {},
      {
        isEdit: false,
        endpoint: `verifyClientUser/${userId}`,
        successMessage: 'Client user is successfully verified',
      }
    )
  )

export const deleteClientCompany = companyId => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `company/client/${companyId}`,
      successMessage: 'Client successfully deleted',
    })
  )
export const deleteSupplierCompany = companyId => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `company/supplier/${companyId}`,
      successMessage: 'Supplier successfully deleted',
    })
  )

export const resendActivationEmail = (userId, values) => async dispatch =>
  dispatch(
    createOrEditItem(values, {
      isEdit: false,
      endpoint: `company/resendActivationEmail/${userId}`,
      successMessage: 'Activation email successfully sent',
    })
  )

export const editCompanyClientPassenger = (clientId, values) => dispatch =>
  dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `companyClients/client/${clientId}`,
      successMessage: 'Client information successfully updated',
    })
  )

export const removeCompanyClientPassenger = (
  clientId,
  values
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      editRequest: 'delete',
      endpoint: `companyClients/client/${clientId}`,
      successMessage: 'Client successfully deleted',
    })
  )

export const companyOptions = async searchItem => {
  const options =
    (await connectedApiService.get(`companyOptions/${searchItem}`)) || {}
  return options.data.hosts
}

export const updateCompanyInvoiceRef = (companyId, values) => dispatch =>
  dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `company/invoiceRefrence/${companyId}`,
      successMessage: 'Company information successfully updated',
    })
  )

export const companyToggle = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'company/toggle',
      successMessage: 'Company updated successfully.',
    })
  )
