import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import DriverApp from './DriverApp'
import Completed from './DriverApp/Bookings/Completed'
import NewBooking from './DriverApp/Bookings/NewBooking'
import Planned from './DriverApp/Bookings/Planned'
import Chauffeurs from './DriverApp/Account/Chauffeurs'
import ChauffeurOnly from './DriverApp/Account/ChauffeurOnly'
import EditChauffeur from './DriverApp/Account/EditChauffeur'
import Cars from './DriverApp/Account/Cars'
import EditCar from './DriverApp/Account/EditCar'
import EditCompany from './DriverApp/Account/EditCompany'
import Settings from './DriverApp/Account/Settings'
import BookingDetails from './DriverApp/Bookings/BookingDetails'
import Assign from './DriverApp/Bookings/Assign'
import HomePage from './DriverApp/User/HomePage'
import Support from './DriverApp/User/Support'
import Login from './DriverApp/Auth/Login'
import Register from './DriverApp/Auth/Register'
import EnterPassword from './DriverApp/Auth/EnterPassword'
import ForgotPassword from './DriverApp/Auth/ForgotPassword'
import SetPassword from './DriverApp/Auth/SetPassword'
import ResetPassword from './DriverApp/Auth/ResetPassword'
import EmailVerification from './DriverApp/Auth/VerifyPartnerEmail'
import Payouts from './DriverApp/Account/Payouts'
import Earnings from './DriverApp/Account/Earnings'
import AssignCollectionAgent from './DriverApp/Bookings/AssignCollectionAgent'
import { partnerHostLive, partnerHostStaging } from '../../../constants'
import AssignGreeter from './DriverApp/Bookings/AssignGreeter'
import BookingDetailsVIP from './DriverApp/Bookings/BookingDetailsVIP'

const isPartnerHost =
  window.location.hostname === partnerHostLive ||
  window.location.hostname === partnerHostStaging
    ? true
    : false

const DriverRoutes = () => (
  <Switch>
    <Route
      exact
      path={`${isPartnerHost ? '/' : '/partner/'}auth/login`}
      component={Login}
    />
    <Route
      exact
      path={`${isPartnerHost ? '/' : '/partner/'}auth/register`}
      component={Register}
    />
    <Route
      exact
      path={`${isPartnerHost ? '/' : '/partner/'}auth/password`}
      component={EnterPassword}
    />
    {/* //// */}
    <Route
      exact
      path={`${isPartnerHost ? '/' : '/partner/'}auth/forgot-password`}
      component={ForgotPassword}
    />
    <Route
      exact
      path={`${isPartnerHost ? '/' : '/partner/'}auth/setpassword/:token`}
      component={SetPassword}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }auth/reset-password/:email/:token`}
      component={ResetPassword}
    />
    <Route
      exact
      path={`${isPartnerHost ? '/' : '/partner/'}auth/:email/emailverification`}
      component={EmailVerification}
    />
    <Route
      exact
      path={`${isPartnerHost ? '/' : '/partner/'}driver/app/:token`}
      component={DriverApp}
    />
    <Route
      exact
      path={`${isPartnerHost ? '/' : '/partner/'}driver/app/:token/home-page`}
      component={HomePage}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/:token/new-bookings`}
      component={NewBooking}
    />
    <Route
      exact
      path={`${isPartnerHost ? '/' : '/partner/'}driver/app/:token/planned`}
      component={Planned}
    />
    <Route
      exact
      path={`${isPartnerHost ? '/' : '/partner/'}driver/app/:token/completed`}
      component={Completed}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/:token/chauffeurs/account`}
      component={Chauffeurs}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/:token/chauffeursOnly/account`}
      component={ChauffeurOnly}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/:token/chauffeur/add`}
      component={EditChauffeur}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/:token/chauffeur/edit/:chauffeurId`}
      component={EditChauffeur}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/:token/cars/account`}
      component={Cars}
    />
    <Route
      exact
      path={`${isPartnerHost ? '/' : '/partner/'}driver/app/:token/car/add`}
      component={EditCar}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/:token/car/edit/:carId`}
      component={EditCar}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/:token/settings/account`}
      component={Settings}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/:token/settings/edit/:companyId`}
      component={EditCompany}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/details/:token/:bookingId`}
      component={BookingDetails}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/details/vip/:token/:bookingId`}
      component={BookingDetailsVIP}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/trip/:token/:bookingId`}
      component={BookingDetails}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/details/chauffeur/:token/:bookingId`}
      component={Assign}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/details/vehicle/:token/:bookingId`}
      component={Assign}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/details/pickup/:token/:bookingId`}
      component={Assign}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/details/confirm/:token/:bookingId`}
      component={Assign}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/details/chauffeurAgent/:token/:bookingId`}
      component={AssignCollectionAgent}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/details/vehicleAgent/:token/:bookingId`}
      component={AssignCollectionAgent}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/details/greeter/assign/:token/:bookingId`}
      component={AssignGreeter}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/details/greeter/confirm/:token/:bookingId`}
      component={AssignGreeter}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/details/confirmAgent/:token/:bookingId`}
      component={AssignCollectionAgent}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/details/confirmAgent/:token/:bookingId`}
      component={AssignCollectionAgent}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/:token/:bookingId/support`}
      component={Support}
    />
    {/* <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/:token/earnings/account`}
      component={Earnings}
    />
    <Route
      exact
      path={`${
        isPartnerHost ? '/' : '/partner/'
      }driver/app/:token/payouts/account`}
      component={Payouts}
    /> */}

    <Redirect
      from={isPartnerHost ? '/' : '/partner'}
      to={isPartnerHost ? '/auth/login' : '/partner/auth/login'}
    />
  </Switch>
)

export default withRouter(DriverRoutes)
