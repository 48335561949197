import React, { useEffect, useState } from 'react'
import { Form, Field, Formik } from 'formik'
import FormField from '../../../../../components/FormField'
import { ChauffeurInitialValues } from 'models/partner'
import { connect } from 'react-redux'
import { Button, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { withRouter, useLocation } from 'react-router-dom'
import SubmitBtn from '../../../../../components/SubmitBtn'
import FormInputPhoneNew, {
  phoneNumberRequiredValidator,
  phoneNumberValidator,
} from '../../../../../components/FormInputPhoneNew'
import FormInputFile from '../../../../../components/FormInputFile'
import { useGetInitialFormValues } from 'hooks'
import {
  handleSubmitValidationErrors,
  requiredStringValidator,
  stringValidator,
} from 'form-validation'
import {
  createChauffeur,
  editChauffeur,
  deleteChauffeur,
} from '../../../../../actions'
import { connectedApiService as api } from '../../../../../index'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { fileToBase64, formatSentenceCase } from '../../../../../helpers'
import * as Yup from 'yup'
import _ from 'lodash'
import { SmallDown } from 'components/Responsive/breakpoints'
import { SmallUp } from 'components/Responsive/breakpoints'
import DefaultDriverDesktopLayout from 'components/layout/DefaultDriverDesktopLayout'
import ConfirmationModal from 'components/confirmationModal'
import { partnerHostLive, partnerHostStaging } from '../../../../../constants'
const Host = window.location.hostname
const validationSchema = Yup.object().shape({
  firstName: requiredStringValidator(),
  lastName: requiredStringValidator(),
  phoneNumber: phoneNumberRequiredValidator().concat(phoneNumberValidator()),
  email: stringValidator(),
})

const itemDataParser = (initialValues, data) =>
  new ChauffeurInitialValues({ ...initialValues, ...data })

const EditChauffeur = ({
  history,
  match,
  createChauffeur,
  editChauffeur,
  deleteChauffeur,
  isCarRental,
  isMeetGreet,
  ...props
}) => {
  const { params: { chauffeurId, token } = {} } = match || {}
  const chauffeurUrl = chauffeurId
    ? `external/chauffeur/edit/${chauffeurId}`
    : null

  let partnerType = localStorage.getItem('partnerType')
    ? localStorage.getItem('partnerType')
    : isCarRental
    ? 'rental'
    : isMeetGreet
    ? 'greeter'
    : 'chauffeur'

  const {
    currentItem,
    getCurrentItem,
    isLoading,
    initialValues,
  } = useGetInitialFormValues(chauffeurUrl, validationSchema, itemDataParser)

  const [countryCode, setCountryCode] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [chauffeurName, setChauffeurName] = useState('')
  let location = useLocation()
  let currentLocation = location.pathname
  let isAssign = currentLocation && currentLocation.includes('/assign')
  let isEdit = currentLocation && currentLocation.includes('/edit')
  let isAdd = currentLocation && currentLocation.includes('/add')

  const onConfirmationModal = async () => {
    await deleteChauffeur(currentItem._id)
    setShowModal(false)
    history.push(
      `${
        Host === partnerHostLive || Host === partnerHostStaging
          ? '/'
          : '/partner/'
      }driver/app/${token}/chauffeurs/account`
    )
  }
  useEffect(() => {
    if (currentItem) {
      let fullName = `${currentItem.firstName} ${currentItem.lastName}`
      setChauffeurName(fullName.substring(0, 25))
    }
  }, [currentItem])
  const onSubmit = async (values, form) => {
    try {
      let id = currentItem ? currentItem._id : null
      values.firstName = formatSentenceCase(values.firstName)
      values.lastName = formatSentenceCase(values.lastName)
      if (
        values.profilePicture &&
        typeof values.profilePicture === 'object' &&
        values.profilePicture.size
      ) {
        const logoBase64 = await fileToBase64(values.profilePicture)
        values.profilePicture = logoBase64 || null
      }
      if (values.logo && typeof values.logo === 'object' && values.logo.size) {
        const logoBase64 = await fileToBase64(values.logo)
        values.profilePicture = logoBase64 || null
        delete values.logo
      }

      if (id) {
        await editChauffeur(values, id)
        history.goBack()
      } else {
        if (!values.profilePicture) delete values.profilePicture
        if (!values.logo) delete values.logo
        const res = await createChauffeur(values, token, partnerType)
        history.goBack()
      }

      form.setSubmitting(false)
    } catch (error) {
      handleSubmitValidationErrors(error, form)
    }
  }

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const url = `external/partnerToken/${token}`
        const { data } = await api.get(url)
        if (data && data.company && data.company.countryCode) {
          setCountryCode(data.company.countryCode)
        }
      } catch (err) {
        console.log(err)
      }
    }
    checkTokenValidity()
  }, [])

  const renderContent = () => {
    return (
      <div className='driver-booking-container' style={{ margin: 0 }}>
        {isAssign && (
          <div className='tabs-container' style={{ width: 'fit-content' }}>
            <div className='d-flex justify-content-start'>
              <div className='assign-stepper'>
                <div className='nav-link completed'>Chauffeur</div>
                <div className='nav-link not-completed'>Vehicle</div>
                <div className='nav-link not-completed'>
                  Pick-up instructions
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='driver-service-card assign-card-top-half'>
          <div className='driver-service-card-header pb-3 pt-3'>
            <span style={currentItem ? { textTransform: 'capitalize' } : {}}>
              {currentItem
                ? `${currentItem.firstName} ${currentItem.lastName}`
                : partnerType === 'rental'
                ? 'Add an agent'
                : partnerType === 'meetGreet'
                ? 'Add a greeter'
                : 'Add a chauffeur'}
            </span>
            {currentItem && (
              <img
                className='delete-icon-desktop'
                src='/images/icons/icon-delete.png'
                onClick={() => {
                  setShowModal(true)
                }}
                alt=''
              />
            )}
          </div>
        </div>
        <div className='driver-service-card assign-card-half'>
          <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={initialValues}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={onSubmit}
          >
            {({ values, isSubmitting, isValid }) => {
              return (
                <>
                  <Form noValidate>
                    <div className='driver-edit-container-desktop'>
                      <div className='image-container-desktop'>
                        <div
                          className={
                            values.profilePicture
                              ? 'chauffeur-pic-container'
                              : 'chauffeur-pic-container-border'
                          }
                        >
                          <img
                            className={
                              values.profilePicture
                                ? 'chauffeur-uploaded-pic'
                                : 'chauffeur-pic'
                            }
                            src={
                              values.profilePicture &&
                              values.profilePicture.name &&
                              values.profilePicture.size
                                ? window.URL.createObjectURL(
                                    values.profilePicture
                                  ) ||
                                  window.webkitURL.createObjectURL(
                                    values.profilePicture
                                  )
                                : values.profilePicture &&
                                  values.profilePicture.name
                                ? values.profilePicture.name
                                : '/images/icons/icon-chauffeur-grey.png'
                            }
                            alt=''
                          />
                        </div>
                        <label htmlFor='profilePicture'>
                          <span>
                            {currentItem
                              ? 'Change profile photo'
                              : 'Upload profile photo'}
                          </span>
                        </label>
                      </div>
                      <div className='driver-edit-wrap'>
                        <div className='driver-fields'>
                          {fields.map((field, i, { component = FormField }) => {
                            return (
                              <>
                                <Field
                                  key={i}
                                  component={component}
                                  {...field}
                                />
                              </>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className='partner-next-container-form'>
                      <Button
                        className='partner-btn-desktop-tertiary select-vehicle'
                        onClick={() => history.goBack()}
                      >
                        Cancel
                      </Button>
                      <SubmitBtn
                        className='partner-btn-desktop-primary select-vehicle'
                        variant='brown'
                        disabled={isSubmitting}
                        style={
                          // isValid
                          //   ?
                          {
                            border: '1px solid #a79779',
                            backgroundColor: '#a79779',
                            boxShadow: 'none',
                          }
                          // : {
                          //     border: '1px solid #a79779',
                          //     backgroundColor: '#a79779',
                          //     boxShadow: 'none',
                          //     opacity: '40%',
                          //     pointerEvents: 'none'
                          //   }
                        }
                      >
                        Save
                      </SubmitBtn>
                    </div>
                  </Form>
                </>
              )
            }}
          </Formik>
          <ConfirmationModal
            onHide={() => {
              setShowModal(false)
            }}
            handleFunction={onConfirmationModal}
            show={showModal}
            confirmationText={'Are you sure you want to delete this chauffeur?'}
          />
        </div>
      </div>
    )
  }

  const fields = [
    {
      name: 'firstName',
      label: 'FIRST NAME*',
    },
    {
      name: 'lastName',
      label: 'LAST NAME*',
    },
    {
      name: 'phoneNumber',
      label: 'MOBILE NUMBER*',
      component: FormInputPhoneNew,
      countryCode:
        (isAdd || (isEdit && currentItem && currentItem.phoneNumber === '')) &&
        countryCode
          ? countryCode
          : null,
      // checkZeroInvalid: true,
      // enableLongNumbers: true,
    },
    {
      name: 'profilePicture',
      label: 'PROFILE PICTURE',
      component: FormInputFile,
      accept: 'image/*',
      hideField: true,
    },
  ]
  const fieldsMobile = [
    {
      name: 'firstName',
      label: 'FIRST NAME*',
    },
    {
      name: 'lastName',
      label: 'LAST NAME*',
    },
    {
      name: 'phoneNumber',
      label: 'MOBILE NUMBER*',
      component: FormInputPhoneNew,
      countryCode:
        (isAdd || (isEdit && currentItem && currentItem.phoneNumber === '')) &&
        countryCode
          ? countryCode
          : null,
      checkZeroInvalid: true,
      enableLongNumbers: true,
    },
  ]

  return (
    <>
      <SmallUp>
        {isEdit ? (
          [
            currentItem ? (
              <DefaultDriverDesktopLayout
                data={currentItem}
                renderContent={renderContent}
              />
            ) : (
              <> </>
            ),
          ]
        ) : (
          <DefaultDriverDesktopLayout
            data={null}
            renderContent={renderContent}
          />
        )}
      </SmallUp>
      <SmallDown>
        <div className='driver-edit-container'>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <img
                className='driver-back-icon-account'
                src='/images/icon-back.png'
                onClick={history.goBack}
                alt=''
              />
              <div className='driver-booking-container-header'>
                {currentItem
                  ? chauffeurName
                  : partnerType === 'rental'
                  ? 'Add an agent'
                  : partnerType === 'meetGreet'
                  ? 'Add a greeter'
                  : 'Add a chauffeur'}
              </div>
              {currentItem && (
                <img
                  style={{
                    position: 'absolute',
                    top: '19px',
                    width: '22px',
                    right: '30px',
                  }}
                  src='/images/icons/icon-delete.png'
                  onClick={() => {
                    setShowModal(true)
                  }}
                  alt=''
                />
              )}
              <Formik
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={initialValues}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={onSubmit}
              >
                {({ values, isSubmitting }) => {
                  const logoField = {
                    name: 'logo',
                    component: FormInputFile,
                    customControlClassName: 'partner-logo-field',
                    accept: 'image/*',
                    needPreview: false,
                    renderField: ({ previewSrc }) => (
                      <OverlayTrigger
                        placement='center'
                        overlay={
                          <Tooltip id='logo-tooltip'>
                            The recommended image size is 300 x 300 pixels.
                          </Tooltip>
                        }
                      >
                        <div className='image-container image-container-Partner'>
                          <div
                            className={
                              values.profilePicture
                                ? 'chauffeur-pic-container'
                                : 'chauffeur-pic-container-border'
                            }
                          >
                            {previewSrc ? (
                              <img
                                className='chauffeur-pic'
                                src={previewSrc}
                                alt=''
                              />
                            ) : (
                              <img
                                alt=''
                                className={
                                  values &&
                                  values.profilePicture &&
                                  values.profilePicture.name
                                    ? 'chauffeur-pic'
                                    : 'chauffeur-pic-not-uploaded'
                                }
                                src={
                                  values.profilePicture &&
                                  values.profilePicture.name &&
                                  values.profilePicture.size
                                    ? window.URL.createObjectURL(
                                        values.profilePicture
                                      ) ||
                                      window.webkitURL.createObjectURL(
                                        values.profilePicture
                                      )
                                    : values.profilePicture &&
                                      values.profilePicture.name
                                    ? values.profilePicture.name
                                    : '/images/icons/icon-chauffeur-grey.png'
                                }
                              />
                            )}
                          </div>
                          <div
                            className='add-link text-brown btn btn-anchor mt-2'
                            style={{ fontSize: '16px' }}
                          >
                            {currentItem ? 'Change photo' : 'Upload photo'}
                          </div>
                        </div>
                      </OverlayTrigger>
                    ),
                  }
                  return (
                    <>
                      <Form noValidate>
                        <div className='body'>
                          <div className='driver-edit-wrap'>
                            <Col
                              md='5'
                              className='company-logo-wrap'
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Field {...logoField} />
                            </Col>
                            <div className='driver-fields'>
                              {fieldsMobile.map(
                                (field, i, { component = FormField }) => {
                                  return (
                                    <>
                                      <Field
                                        key={i}
                                        component={component}
                                        {...field}
                                      />
                                    </>
                                  )
                                }
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='driver-booking-cta-footer-acc'>
                          <SubmitBtn
                            className='select-btn select-vehicle'
                            style={{
                              width: '100%',
                              fontWeight: '900',
                              fontSize: '16px',
                              textShadow: '0.25px 0 #ffffff',
                            }}
                            disabled={isSubmitting}
                          />
                        </div>
                      </Form>
                      <ConfirmationModal
                        onHide={() => {
                          setShowModal(false)
                        }}
                        handleFunction={onConfirmationModal}
                        show={showModal}
                        confirmationText={
                          'Are you sure you want to delete this chauffeur?'
                        }
                        confirmationTitle={'Delete chauffeur'}
                        changeConfirmationTitle
                      />
                    </>
                  )
                }}
              </Formik>
            </>
          )}
        </div>
      </SmallDown>
    </>
  )
}

const mapStateToProps = ({
  auth: { isCarRental = null, isMeetGreet = null },
}) => ({
  isCarRental,
  isMeetGreet,
})

export default withRouter(
  connect(mapStateToProps, { createChauffeur, editChauffeur, deleteChauffeur })(
    EditChauffeur
  )
)
