import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { getIn } from 'formik'
import LoadingSpinner from './LoadingSpinner'
import { isMobile } from '../helpers'
import DynamicDropdown from './DynamicDropdown'
import { connectedApiService } from 'index'
import Pagination from './Pagination'
import TooltipOnHover from './TooltipOnHover'
import AddLink from './AddLink'
const isMobileBrowser = isMobile()
const TableHeadCell = (
  {
    title,
    titleClassName,
    titleIcon,
    refereesTable,
    iconClassName,
    onClick,
    isClientAdminSide = false,
    isAlertsAdminSide = false,
  },
  i
) => (
  <th
    className={titleClassName || ''}
    style={
      isClientAdminSide
        ? { padding: '0.5rem' }
        : isAlertsAdminSide
        ? { fontSize: '14px', padding: '5px' }
        : {}
    }
  >
    {title ? (
      title.toLowerCase() === 'margin' ? (
        <>
          {title}{' '}
          <TooltipOnHover
            delay={200}
            placement='bottom-end'
            onMouseEnter={() => {}}
            component={
              <div style={{ display: 'block' }}>
                <div className='d-flex align-items-center'>
                  <div className='margin-dot-green' />
                  {'Good (>20%)'}
                </div>
                <div className='d-flex align-items-center'>
                  <div className='margin-dot-yellow' />
                  {'Moderate (10% - 19.99%)'}
                </div>
                <div className='d-flex align-items-center'>
                  <div className='margin-dot-orange' />
                  {'Low (0.01% - 9,99%)'}
                </div>
                <div className='d-flex align-items-center'>
                  <div className='margin-dot-red' />
                  {'Negative (< 0%)'}
                </div>
                <div className='d-flex align-items-center'>
                  <div className='margin-dot-blue' />
                  {'High (> 50%)'}
                </div>
              </div>
            }
          >
            <img
              className={'new-info-icon'}
              style={{
                marginLeft: '0.1rem',
                height: '0.938rem',
              }}
              src='/images/icons/new-info-icon.png'
              alt=''
            />
          </TooltipOnHover>
        </>
      ) : (
        title
      )
    ) : (
      ''
    )}
    {titleIcon && (
      <img
        src={titleIcon}
        alt=''
        className={iconClassName}
        onClick={onClick}
      ></img>
    )}
    {title && title === 'Bookings completed' && refereesTable ? (
      <TooltipOnHover
        delay={200}
        placement={'top-start'}
        isMobileBrowser={isMobileBrowser}
        onMouseEnter={() => {}}
        component={
          <div>
            To earn ROLZO credits, bookings must be completed, paid in full, and
            claim-free.
          </div>
        }
      >
        <img
          alt=''
          className={'new-info-icon'}
          style={{ marginLeft: '0.375rem' }}
          src='/images/icons/new-info-icon.png'
        />
      </TooltipOnHover>
    ) : (
      <></>
    )}
  </th>
)

const TableHead = ({
  columns = [],
  isClientAdminSide = false,
  isAlertsAdminSide = false,
}) => (
  <thead>
    <tr>
      {columns.map((cell, i) => (
        <TableHeadCell
          {...cell}
          key={String(i)}
          isClientAdminSide={isClientAdminSide}
          isAlertsAdminSide={isAlertsAdminSide}
        />
      ))}
    </tr>
  </thead>
)

const TableCell = ({
  cell: { renderCol, selector, colClassName, defaultValue = '' } = {},
  row,
  isClientAdminSide = false,
  isAlertsAdminSide = false,
  isBookingListAdmin = false,
}) => {
  const isCancelled =
    row.status === 'cancellation_accepted_by_agent' ||
    row.status === 'cancelled_by_agent'
  return (
    <td
      className={
        row && row.dispatched ? 'dispatch-grey-row' : colClassName || ''
      }
      style={
        isClientAdminSide || isAlertsAdminSide
          ? { padding: '5px' }
          : isBookingListAdmin
          ? row && row.payByLink && row.paymentStatus !== 'paid' && !isCancelled
            ? { borderColor: '#d0021b' }
            : {}
          : {}
      }
    >
      {renderCol ? renderCol(row) : getIn(row, selector) || defaultValue || ''}
    </td>
  )
}

const TableRow = ({
  row,
  columns,
  onRowClick,
  isClientAdminSide = false,
  isAlertsAdminSide = false,
  isBookingListAdmin = false,
}) => (
  <tr onClick={onRowClick(row)}>
    {columns.map((cell, index) => (
      <TableCell
        cell={cell}
        row={row}
        key={String(index)}
        isClientAdminSide={isClientAdminSide}
        isAlertsAdminSide={isAlertsAdminSide}
        isBookingListAdmin={isBookingListAdmin}
      />
    ))}
  </tr>
)

const TableBody = ({
  columns = [],
  list = [],
  onRowClick = () => {},
  isClientAdminSide = false,
  isAlertsAdminSide = false,
  isBookingListAdmin = false,
}) => (
  <tbody>
    {list.map((row, index) => (
      <TableRow
        key={String(index)}
        row={row}
        columns={columns}
        onRowClick={onRowClick}
        isClientAdminSide={isClientAdminSide}
        isAlertsAdminSide={isAlertsAdminSide}
        isBookingListAdmin={isBookingListAdmin}
      />
    ))}
  </tbody>
)

const TableEmptyState = ({
  list,
  isLoading,
  showNoData,
  renderNoData,
  className,
}) => {
  if (list && list.length) {
    return null
  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {!!showNoData && (
            <>
              {!!renderNoData ? (
                renderNoData()
              ) : (
                <div
                  className={
                    className === 'light-theme-table-dispatch'
                      ? 'text-center h5 mt-5 pt-3'
                      : 'text-center h5'
                  }
                >
                  Nothing to display...
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

const TablePagination = ({
  isBookingListAdmin,
  isAlerts,
  pageLimit,
  setPageLimit,
  totalItems,
  onLoadNextPage,
  showPagination,
  list,
  setPagination,
  pagination = {},
  getNextPage,
  currentPage,
  setCurrentPage,
}) => {
  if (!!showPagination && list && totalItems > 10) {
    return (
      <div className='text-center  pagination-parent align-items-center align-items-md-end '>
        {/* <Button variant='link' className='text-brown' onClick={() => getNextPage()}>
          Show more
        </Button> */}
        <Pagination
          isAlerts={isAlerts}
          isBookingListAdmin={isBookingListAdmin}
          list={list}
          className='pagination-bar p-0'
          currentPage={pagination?.page}
          setPagination={setPagination}
          totalCount={totalItems}
          pageSize={pageLimit}
          setPageSize={setPageLimit}
          onPageChange={page => {
            isAlerts
              ? setPagination({
                  ...pagination,
                  page: page,
                })
              : getNextPage(page)
            window.scrollTo({ top: 30, behavior: 'smooth' })
          }}
        />
      </div>
    )
  }

  return null
}

export default function DataTable({
  isBookingListAdmin = false,
  isAlerts = false,
  setScrollPosition,
  scrollPosition = null,
  pageLimit,
  setPageLimit,
  setPagination,
  totalItems,
  currentPage,
  setCurrentPage,
  getNextPage,
  className,
  list,
  columns = [],
  isLoading = false,
  pagination = {},
  showNoData = true,
  showPagination = false,
  onLoadNextPage,
  onRowClick = () => {},
  renderNoData = null,
  showHead = true,
  increaseMaxWidth = false,
  isAdmin = false,
  isClientTable = false,
  removeMaxWidth = false,
  addable = false,
  linkedCompanyId = '',
  linkedCompanyName = '',
  reloadList,
  isClientAdminSide = false,
  isAlertsAdminSide = false,
  exportTable = false,
  exportTableId = '',
  addTableItem = false,
  onAddItem = () => {},
}) {
  const [affiliate, setAffiliate] = useState('')
  const handleScroll = e => {
    if (e.target.className === 'table-responsive') {
      setScrollPosition(e.target.scrollLeft)
    }
  }
  useEffect(() => {
    if (scrollPosition !== null)
      window.addEventListener('scroll', handleScroll, true)
  }, [])
  const onAttachAffiliate = async () => {
    const addAffiliate =
      (await connectedApiService.post(`hostAgencyList/addAffiliate`, {
        linkedCompanyId: linkedCompanyId,
        linkedCompanyName: linkedCompanyName,
        affiliate: affiliate,
      })) || {}
  }
  if (!columns || !columns.length) {
    return null
  }

  return (
    <>
      <TableEmptyState
        list={list}
        isLoading={isLoading}
        showNoData={showNoData}
        renderNoData={renderNoData}
        className={className}
      />
      {!!list && !!list.length && (
        <div className={`data-table-wrap hover-table ${className}`}>
          <Table
            id={exportTable ? exportTableId : ''}
            className={
              isAdmin
                ? isMobileBrowser
                  ? 'data-table'
                  : 'data-table data-table-admin'
                : 'data-table'
            }
            responsive={!isClientTable}
            style={
              increaseMaxWidth
                ? { maxWidth: '1000px' }
                : removeMaxWidth
                ? { maxWidth: 'unset' }
                : {}
            }
          >
            {showHead && (
              <TableHead
                columns={columns}
                isClientAdminSide={isClientAdminSide}
                isAlertsAdminSide={isAlertsAdminSide}
              />
            )}
            <TableBody
              columns={columns}
              list={list}
              onRowClick={onRowClick}
              isClientAdminSide={isClientAdminSide}
              isAlertsAdminSide={isAlertsAdminSide}
              isBookingListAdmin={isBookingListAdmin}
            />
            {addTableItem ? (
              <AddLink
                onClick={() => {
                  onAddItem()
                }}
                className='mt-2 ml-0 mb-3'
              >
                <small>Add partner </small>
              </AddLink>
            ) : (
              <></>
            )}
            {addable && (
              <span>
                <br></br>
                <span>
                  <DynamicDropdown onChange={value => setAffiliate(value)} />
                </span>
                <br></br>
                <span>
                  <Button
                    className='partner-btn-desktop-secondary select-vehicle'
                    variant='brown'
                    type='button'
                    onClick={() => {
                      if (affiliate) {
                        onAttachAffiliate()
                        setAffiliate('')
                        reloadList()
                      }
                    }}
                  >
                    Attach affiliate
                  </Button>
                </span>
              </span>
            )}
          </Table>
          <TablePagination
            isBookingListAdmin={isBookingListAdmin}
            isAlerts={isAlerts}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            pagination={pagination}
            setPagination={setPagination}
            totalItems={totalItems}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            list={list}
            showPagination={showPagination}
            onLoadNextPage={onLoadNextPage}
            getNextPage={getNextPage}
          />
        </div>
      )}
    </>
  )
}
