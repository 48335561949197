import React, { useEffect, useState } from 'react'
import { Form, Field, Formik, FieldArray } from 'formik'
import FormField from '../../../../../components/FormField'
import { CompanyInitialValues } from 'models/partner'
import { connect } from 'react-redux'
import { Col, Button } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import SubmitBtn from '../../../../../components/SubmitBtn'
import { useGetInitialFormValues } from 'hooks'
import {
  emailValidator,
  handleSubmitValidationErrors,
  requiredStringValidator,
  stringValidator,
} from 'form-validation'
import { editCompany } from '../../../../../actions'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import * as Yup from 'yup'
import _ from 'lodash'
import { SmallDown } from 'components/Responsive/breakpoints'
import { SmallUp } from 'components/Responsive/breakpoints'
import DefaultDriverDesktopLayout from 'components/layout/DefaultDriverDesktopLayout'
import FormInputPhoneNew from 'components/FormInputPhoneNew'
import LocationAutocomplete from 'components/LocationAutocomplete'
import AddLink from 'components/AddLink'
import { Link } from 'react-router-dom'
import BankAccounts from 'components/Payouts/BankAccounts'
import { europeanCountriesCodes } from '../../../../../constants'
import FormCheckbox from 'components/FormCheckbox'

const validationSchema = Yup.object().shape({
  name: requiredStringValidator(),
  website: stringValidator(),
})

const itemDataParser = (initialValues, data) =>
  new CompanyInitialValues({ ...initialValues, ...data })

const vatCountries = ['GB', 'AE']
const gstCountries = ['AU', 'CA']
const jctCountries = ['JP']

const EditCompany = ({ history, match, editCompany }) => {
  const { params: { companyId } = {} } = match || {}

  const [registeredLabel, setRegisteredLabel] = useState(
    'Business not tax registered'
  )
  const [taxLabel, setTaxLabel] = useState('TAX NUMBER*')
  const chauffeurUrl = companyId ? `external/company/edit/${companyId}` : null

  const {
    currentItem,
    getCurrentItem,
    initialValues,
    isLoading,
  } = useGetInitialFormValues(chauffeurUrl, validationSchema, itemDataParser)

  const fields = [
    {
      name: 'name',
      label: 'COMPANY NAME',
      md: 6,
    },
    {
      name: 'address',
      label: 'ADDRESS',
      md: 6,
    },
    {
      name: 'website',
      label: 'COMPANY WEBSITE',
    },
  ]

  const getAddressOptions = place => {
    return {
      bounds: place ? place.bounds : null,
      componentRestrictions:
        place && place.country
          ? {
              country: place.country.toLowerCase(),
            }
          : null,
    }
  }

  const contactDetails = [
    {
      name: 'contactsFullName',
      label: 'FULL NAME',
      component: FormField,
    },
    {
      name: 'contactsEmail',
      label: 'EMAIL',
      type: 'email',
      component: FormField,
    },
    {
      name: 'contactsPhoneNumber',
      label: 'PHONE NUMBER',
      className: 'partner-section',
      component: FormInputPhoneNew,
      enableLongNumbers: true,
    },
  ]

  const getContactValidationSchema = () => {
    return Yup.object().shape({
      contactsFullName: requiredStringValidator({ defaultValue: 'Team' }),
      contactsEmail: requiredStringValidator().concat(emailValidator()),
      contactsPhoneNumber: requiredStringValidator(),
    })
  }

  const onSubmit = async (values, form) => {
    try {
      // console.log('values', values)
      await editCompany(values, companyId)
      await getCurrentItem()

      form.setSubmitting(false)
      history.goBack()
    } catch (error) {
      console.log('Edit company error', error)
      handleSubmitValidationErrors(error, form)
    }
  }

  useEffect(() => {
    if (currentItem) {
      const country = currentItem.place ? currentItem.place.country : null
      const isEurope = currentItem.place
        ? europeanCountriesCodes.includes(currentItem.place.country)
        : null

      const label1 =
        vatCountries.includes(country) || isEurope
          ? 'Business not VAT registered'
          : gstCountries.includes(country)
          ? 'Business not GST registered'
          : jctCountries.includes(country)
          ? 'Business not JCT registered'
          : 'Business not tax registered'

      setRegisteredLabel(label1)

      const label2 =
        vatCountries.includes(country) || isEurope
          ? 'VAT NUMBER*'
          : gstCountries.includes(country)
          ? 'GST NUMBER*'
          : jctCountries.includes(country)
          ? 'JCT NUMBER*'
          : country === 'US'
          ? 'TAX ID (EIN)*'
          : 'TAX NUMBER*'

      setTaxLabel(label2)
    }
  }, [currentItem])
  const renderContent = () => {
    return (
      <>
        {currentItem && (
          <div className='driver-booking-container' style={{ margin: 0 }}>
            <div className='driver-service-card assign-card-top-half'>
              <div className='driver-service-card-header pb-3 pt-3'>
                <span>Company information </span>
              </div>
            </div>
            <div
              className='driver-service-card assign-card-half'
              style={{ padding: '0em 0em' }}
            >
              <Formik
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={initialValues}
                validateOnBlur={true}
                validateOnChange={false}
                onSubmit={onSubmit}
              >
                {({ values, isSubmitting, setFieldValue, resetForm }) => {
                  const addNewContact = (evt, { push }) => {
                    evt.preventDefault()
                    push(
                      getContactValidationSchema(
                        !values.contactDetails.length
                      ).cast()
                    )
                  }
                  const remove = (evt, { remove }, index) => {
                    evt.preventDefault()
                    remove(index)
                  }
                  const onCityChange = () => {
                    setFieldValue('address', null)
                  }
                  const addressOptions = getAddressOptions(values.place)
                  const companyFields = [
                    {
                      name: 'name',
                      label: 'COMPANY NAME',
                    },
                    {
                      name: 'place',
                      label: 'CITY',
                      component: LocationAutocomplete,
                      searchOptions: { types: ['(cities)'] },
                      confirmOnSelect: true,
                      displayKey: 'cityState',
                      needCityState: true,
                      showPin: true,
                      needBounds: true,
                      handleChange: onCityChange,
                    },
                    {
                      name: 'address',
                      label: 'ADDRESS LINE 1',
                      component: LocationAutocomplete,
                      searchOptions: addressOptions,
                      showPin: true,
                      confirmOnSelect: true,
                      needFullAddress: true,
                      displayKey: 'fullAddress',
                      disabled: !values.place,
                    },
                    {
                      name: 'addressLine2',
                      label: 'ADDRESS LINE 2',
                    },
                    {
                      name: 'website',
                      label: 'website',
                    },
                  ]

                  return (
                    <>
                      <Form noValidate>
                        <div
                          className='driver-edit-container-desktop'
                          style={{ padding: '1.5em 1.5em' }}
                        >
                          <div className='driver-edit-wrap'>
                            <div
                              className='driver-fields'
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                              }}
                            >
                              {companyFields.map(
                                (field, i, { component = FormField }) => (
                                  <Col>
                                    <Field
                                      key={i}
                                      component={component}
                                      {...field}
                                    />
                                  </Col>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='edit-company'>
                          <div className='driver-service-card-header pb-3 pt-3'>
                            <span>Tax information </span>
                          </div>
                        </div>
                        <div
                          className='driver-edit-container-desktop'
                          style={{ padding: '1.5em 1.5em' }}
                        >
                          <div className='driver-edit-wrap'>
                            <div className='driver-fields'>
                              <Col md='6' className='pr-0'>
                                <Field
                                  key={'taxNumber'}
                                  component={FormField}
                                  name='billing.taxNumber'
                                  label={`${taxLabel}`}
                                  disabled={values.billing.isTaxRegistered}
                                />
                                <Field
                                  label={`${registeredLabel}`}
                                  checked={values.billing.isTaxRegistered}
                                  component={FormCheckbox}
                                  name={'billing.isTaxRegistered'}
                                  controlId={`billing.isTaxRegistered`}
                                  className='text-nowrap'
                                />
                              </Col>
                            </div>
                          </div>
                        </div>
                        <div className='edit-company'>
                          <div className='driver-service-card-header pb-3 pt-3'>
                            <span>Contact information </span>
                          </div>
                        </div>
                        <div
                          className='driver-edit-container-desktop'
                          style={{ padding: '1.5em 1.5em' }}
                        >
                          <div className='driver-edit-wrap'>
                            <div className='driver-fields'>
                              <FieldArray
                                name='contactDetails'
                                validateOnChange={false}
                                render={arrayHelpers => (
                                  <div
                                    style={{
                                      display: 'grid',
                                      gridTemplateColumns: '1fr 1fr',
                                    }}
                                  >
                                    {values.contactDetails.map(
                                      (member, index) => (
                                        <>
                                          {contactDetails.map(
                                            (field, fieldIndex) => {
                                              if (
                                                index ===
                                                  values.contactDetails.length -
                                                    1 &&
                                                fieldIndex ===
                                                  contactDetails.length - 1
                                              ) {
                                                return (
                                                  <>
                                                    <Col
                                                      key={index}
                                                      className={
                                                        field.className || ''
                                                      }
                                                    >
                                                      <Field
                                                        {...field}
                                                        name={`contactDetails[${index}].${field.name}`}
                                                        component={
                                                          field.component
                                                        }
                                                        controlId={`contactDetails[${index}].${field.name}`}
                                                      />
                                                      {console.log(
                                                        'index',
                                                        index
                                                      )}
                                                      {console.log(
                                                        'fieldIndex',
                                                        fieldIndex
                                                      )}
                                                      {index !== 0 &&
                                                      fieldIndex ===
                                                        contactDetails.length -
                                                          1 ? (
                                                        <Col
                                                          key={`remove${String(
                                                            index
                                                          )}`}
                                                          className='pl-0 mt-5 d-flex justify-content-start'
                                                        >
                                                          <AddLink
                                                            node={Link}
                                                            to={`/`}
                                                            onClick={evt =>
                                                              addNewContact(
                                                                evt,
                                                                arrayHelpers
                                                              )
                                                            }
                                                            className='text-brown pointer-link'
                                                          >
                                                            <small>
                                                              {' '}
                                                              Add a contact
                                                            </small>
                                                          </AddLink>
                                                        </Col>
                                                      ) : (
                                                        <>
                                                          {index === 0 ? (
                                                            <Col
                                                              key={`remove${String(
                                                                index
                                                              )}`}
                                                              className='pl-0 mt-5 d-flex justify-content-start'
                                                            >
                                                              <AddLink
                                                                node={Link}
                                                                to={`/`}
                                                                onClick={evt =>
                                                                  addNewContact(
                                                                    evt,
                                                                    arrayHelpers
                                                                  )
                                                                }
                                                                className='text-brown pointer-link'
                                                              >
                                                                <small>
                                                                  {' '}
                                                                  Add a contact{' '}
                                                                </small>
                                                              </AddLink>
                                                            </Col>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </>
                                                      )}
                                                    </Col>

                                                    <>
                                                      {index !== 0 ? (
                                                        <Col
                                                          key={`remove${String(
                                                            index
                                                          )}`}
                                                          md={4}
                                                          style={{
                                                            paddingTop: '2rem',
                                                          }}
                                                        >
                                                          <div className='remove-section'>
                                                            <img
                                                              className='delete-icon-desktop'
                                                              src='/images/icons/icon-delete.png'
                                                              onClick={evt =>
                                                                remove(
                                                                  evt,
                                                                  arrayHelpers,
                                                                  index
                                                                )
                                                              }
                                                              alt=''
                                                            />
                                                          </div>
                                                        </Col>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  </>
                                                )
                                              }
                                              return (
                                                <>
                                                  <Col
                                                    key={index}
                                                    className={
                                                      field.className || ''
                                                    }
                                                  >
                                                    <Field
                                                      {...field}
                                                      name={`contactDetails[${index}].${field.name}`}
                                                      component={
                                                        field.component
                                                      }
                                                      controlId={`contactDetails[${index}].${field.name}`}
                                                    />
                                                  </Col>
                                                  {index !== 0 &&
                                                  fieldIndex ===
                                                    contactDetails.length -
                                                      1 ? (
                                                    <Col
                                                      key={`remove${String(
                                                        index
                                                      )}`}
                                                      md={4}
                                                      style={{
                                                        paddingTop: '2rem',
                                                      }}
                                                    >
                                                      <div className='remove-section'>
                                                        <img
                                                          className='delete-icon-desktop'
                                                          src='/images/icons/icon-delete.png'
                                                          onClick={evt =>
                                                            remove(
                                                              evt,
                                                              arrayHelpers,
                                                              index
                                                            )
                                                          }
                                                          alt=''
                                                        />
                                                      </div>
                                                    </Col>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              )
                                            }
                                          )}
                                          {/* This code is so bad i feel like throwing up... i hope i dont get to see this part of the code often */}
                                          {index ===
                                            values.contactDetails.length - 1 ||
                                          index === 0 ? (
                                            <br />
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      )
                                    )}
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='edit-company'>
                          <div className='driver-service-card-header pb-3 pt-3'>
                            <span>Payout information </span>
                          </div>
                        </div>
                        <div
                          className='driver-edit-container-desktop'
                          style={{ padding: '1.5em 1.5em 2em 1.5em' }}
                        >
                          <BankAccounts
                            isPartner
                            companyId={companyId}
                            isPartnerPayoutTerm={currentItem.payout_terms}
                          />
                        </div>
                        <div className='partner-next-container-form'>
                          <Button
                            className='partner-btn-desktop-tertiary select-vehicle'
                            onClick={() => history.goBack()}
                          >
                            Cancel
                          </Button>
                          <SubmitBtn
                            className='partner-btn-desktop-primary select-vehicle'
                            variant='brown'
                            disabled={isSubmitting}
                            style={{
                              border: '1px solid #a79779',
                              backgroundColor: '#a79779',
                            }}
                          >
                            Save
                          </SubmitBtn>
                        </div>
                      </Form>
                    </>
                  )
                }}
              </Formik>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <SmallUp>
        {currentItem ? (
          <DefaultDriverDesktopLayout
            data={currentItem}
            renderContent={renderContent}
          />
        ) : (
          <> </>
        )}
      </SmallUp>
      <SmallDown>
        <div className='driver-edit-container'>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <img
                className='driver-back-icon-account'
                src='/images/icon-back.png'
                onClick={history.goBack}
                alt=''
              />
              <div className='driver-booking-container-header'>
                {currentItem && currentItem.name}
              </div>
              <Formik
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={initialValues}
                validateOnBlur={true}
                validateOnChange={false}
                onSubmit={onSubmit}
              >
                {({ values, isSubmitting }) => {
                  return (
                    <>
                      <Form noValidate>
                        <div className='body'>
                          <div className='driver-edit-wrap'>
                            <div className='driver-fields'>
                              {fields.map(
                                (field, i, { component = FormField }) => {
                                  return (
                                    <>
                                      <Field
                                        key={i}
                                        component={component}
                                        {...field}
                                      />
                                    </>
                                  )
                                }
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='driver-booking-cta-footer-acc'>
                          <SubmitBtn
                            className='select-btn select-vehicle'
                            style={{
                              width: '100%',
                              fontWeight: '900',
                              fontSize: '16px',
                              textShadow: '0.25px 0 #ffffff',
                            }}
                            disabled={isSubmitting}
                          />
                        </div>
                      </Form>
                    </>
                  )
                }}
              </Formik>
            </>
          )}
        </div>
      </SmallDown>
    </>
  )
}

export default withRouter(connect(null, { editCompany })(EditCompany))
