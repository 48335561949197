import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { getIn } from 'formik'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { getRoundedTime } from '../helpers'

const { Control, Group, Label } = Form

const CustomInput = React.forwardRef(({ ...props }, ref) => (
  <input {...props} ref={ref} readOnly={true} />
))

export default function FormInputDatepicker({
  field: {
    value = '',
    placeholder = moment().format('ddd, DD MMM YYYY'),
    ...field
  } = {},
  form: { touched = {}, errors = {}, setFieldValue } = {},
  icon,
  groupProps = {},
  handleChange,
  hintText,
  label,
  isTimePicker = false,
  isDatePicker = false,
  isBannerDate = false,
  isEventDate = false,
  controlId,
  isISODate,
  isClearable = false,
  isToDate = false,
  isSearchField = false,
  roundFormField = false,
  className,
  minDate,
  isDropOffDate = false,
  dropOffDateValue = null,
  pickUpValue = null,
  isStopDate = false,
  stopDateValue = null,
  onHandleChange,
  timeIntervals = 5,
  isOnlyDate = false,
  pickUpDate = null,
  extendCalenderWidth = false,
  extendCalenderPayout = false,
  dayNumber = 0,
  setCheckFunction,
  check,
  whiteFieldInput = false,
  sameDayDropOff = false,
  ...props
}) {
  const [active, setActive] = useState('')
  // const [check, setCheck] = useState(false)
  const showError = !!getIn(touched, field.name) && !!getIn(errors, field.name)

  //const formatValue = isTimePicker ? 'LT' : 'DD/MM/YYYY'
  const newFormatValue = isTimePicker ? 'HH:mm' : 'DD/MM/YYYY'

  const getValues = value => {
    var b = value.split('/')
    var c = b[2] + b[1] + b[0]
    return parseInt(c)
  }
  const addDayToDate = (date, dayNumber) => {
    let formattedDate = moment(date, 'DD/MM/YYYY')
      .toDate()
      .toString()
    let result = new Date(formattedDate)
    result.setDate(result.getDate() + dayNumber)
    return moment(result).format('DD/MM/YYYY')
  }
  useEffect(() => {
    if (!check && isDropOffDate) {
      var value = 0
      if (
        dropOffDateValue.getMonth &&
        typeof dropOffDateValue.getMonth === 'function'
      ) {
        value = moment(dropOffDateValue).format(newFormatValue)
      } else {
        value = dropOffDateValue
      }
      if (!check && setCheckFunction) {
        const minValue = minDate
          ? moment(minDate)
              .add(1, 'd')
              .format(newFormatValue)
          : null
        if (getValues(minValue) > getValues(value)) {
          field.onChange(minValue)
          if (setFieldValue) {
            setFieldValue(field.name, minValue)
            setCheckFunction(true)
          }
        }
      } else {
        const minValue = minDate ? moment(minDate).format(newFormatValue) : null
        if (getValues(minValue) > getValues(value)) {
          field.onChange(minDate)
          if (setFieldValue) {
            setFieldValue(field.name, minDate)
          }
        }
      }
    }
  }, [minDate])

  useEffect(() => {
    if (isStopDate) {
      var value = 0
      if (pickUpDate.getMonth && typeof pickUpDate.getMonth === 'function') {
        value = moment(pickUpDate).format(newFormatValue)
      } else {
        value = pickUpDate
      }
      value = addDayToDate(value, dayNumber)
      field.onChange(value)
      if (setFieldValue) {
        setFieldValue(field.name, value)
      }
    }
  }, [pickUpDate])

  const onChange = date => {
    setActive('active')
    const value = date ? moment(date).format(newFormatValue) : null
    if (minDate && minDate > value) {
      if (field.name === 'date-to-search') {
        field.onChange(null)
      }
      return
    }
    field.onChange(value)
    if (setFieldValue) {
      setFieldValue(field.name, value)
      if (field.name === 'pickUpDate') {
        setFieldValue(
          'dropOffDate',
          sameDayDropOff
            ? moment(date).format(newFormatValue)
            : moment(date)
                .add(1, 'd')
                .format(newFormatValue)
        )
      }
    }
    if (handleChange) {
      handleChange(value)
    }
    if (onHandleChange) {
      onHandleChange()
    }
  }

  const timePickerProps = isTimePicker
    ? {
        showTimeSelect: true,
        showTimeSelectOnly: true,
        timeIntervals: timeIntervals,
        timeCaption: 'Time',
        dateFormat: 'HH:mm',
        timeFormat: 'HH:mm',
        placeholderText: getRoundedTime(moment()).format('HH:mm'),
      }
    : isDatePicker
    ? {
        placeholderText: moment().format('ddd, DD MMM YYYY'),
      }
    : null

  let selectedValue = null
  if (value) {
    if (isSearchField) {
      if (isToDate) {
        selectedValue = isISODate
          ? moment(value, 'YYYY-MM-DD HH:mm').toDate()
          : moment(value, newFormatValue).toDate()
      } else {
        if (isOnlyDate) {
          selectedValue = moment(value, 'YYYY-MM-DD').toDate()
        } else {
          selectedValue = isISODate
            ? moment(value, 'YYYY-MM-DD HH:mm').toDate()
            : moment(value, newFormatValue).toDate()
        }
      }
    } else if (isBannerDate || isEventDate) {
      const initialDate = moment(value, 'DD/MM/YYYY', true)
      const isValid = initialDate.isValid()
      const formattedDate = isValid
        ? value
        : moment.utc(value).format('DD/MM/YYYY')
      selectedValue = moment(formattedDate, 'DD/MM/YYYY').toDate()
    } else {
      selectedValue = isISODate
        ? moment(value).toDate()
        : moment(value, newFormatValue).toDate()
    }
  }

  return (
    <Group
      controlId={controlId || field.name}
      className={
        whiteFieldInput
          ? 'white-field-input'
          : roundFormField
          ? 'round-date-group'
          : extendCalenderWidth
          ? 'extend-calender'
          : extendCalenderPayout
          ? 'extend-calender-payout'
          : ''
      }
    >
      {!!label && <Label className='form-label'>{label}</Label>}
      <Control
        as={DatePicker}
        {...field}
        selected={selectedValue}
        {...props}
        minDate={minDate}
        isInvalid={showError}
        onChange={onChange}
        placeholderText={placeholder}
        popperPlacement='bottom-end'
        autoComplete='off'
        popperModifiers={{
          flip: {
            behavior: ['bottom'], // don't allow it to flip to be above
          },
          preventOverflow: {
            enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
          },
          hide: {
            enabled: false, // turn off since needs preventOverflow to be enabled
          },
        }}
        className={`custom-datepicker-control form-control ${
          !!isTimePicker ? 'custom-timepicker-control' : ''
        } ${!!value && !!isClearable ? 'show-clear' : null} ${active ||
          className}`}
        calendarClassName='custom-datepicker-calendar'
        dateFormat='EEE, do MMM yyyy'
        isClearable={!!isClearable}
        {...timePickerProps}
        customInput={<CustomInput />}
      />
      {!!hintText && (
        <Form.Text className='text-muted textarea-hint'>{hintText}</Form.Text>
      )}
      {!!showError && (
        <Control.Feedback className='d-block' type='invalid'>
          {getIn(errors, field.name)}
        </Control.Feedback>
      )}
    </Group>
  )
}
