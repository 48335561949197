import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

export default function SubmitBtn({
  className = '',
  title = 'Save',
  disabled = false,
  isLoading = false,
  ...props
}) {
  return (
    <Button
      className={`submit-btn ${className} ${
        disabled || isLoading ? 'disabled' : ''
      }`}
      variant='brown'
      disabled={disabled || isLoading}
      type='submit'
      {...props}
    >
      {title}

      {isLoading && (
        <Spinner
          as='span'
          animation='border'
          size='sm'
          role='status'
          aria-hidden='true'
          className='ml-2'
        />
      )}
    </Button>
  )
}
